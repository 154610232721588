<template>
  <Form @submit="onSubmit">
    <Textarea class="w-full" rows="10" v-model="targetsInput"></Textarea>

    <div v-if="!validInput">
      <p class="text-red-500"><i class="fa fa-warning"></i> Invalid input - {{ errorMessage }}
      </p>
    </div>
    <div v-else>
      <p class="text-green-500"><i class="fa fa-check"></i> Valid input</p>
    </div>


    <div class="flex flex-row flex-wrap justify-content-end">
      <Button
        data-cy="sceneAddTargetsForm__addTargets"
        :label="`Add targets`"
        type="submit"
        icon="fa-solid fa-trowel"
        class="p-button-success mr-2 mb-2 mlwn-button-submit"
        :disabled="!validInput"
      >
      </Button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { Form } from "vee-validate";

// props
const props = defineProps({
  sceneId: {
    type: String,
    required: true,
  },
  targetLayoutId: {
    type: Number,
    required: true,
  },
});

// composables
import { useTargets } from "@/composables/useTargets";
import { computed, ref } from "vue";
import { MutationCreateTargetsArgs } from "@/gql/graphql";
import { useToast } from "primevue/usetoast";

// composables usage
const { createTargets, getTargetFilePersistenceLevels } = useTargets();
const toast = useToast();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();

// refs
const DELIMITER = ";";
const targetsInput = ref("");
const errorMessage = ref("");

// computed
const validInput = computed(() => validateTargetInput(targetsInput.value));

// emits
const emit = defineEmits(["targets-added"]);

// methods
const onSubmit = async () => {
  const targets = parseTargetInput(targetsInput.value);
  try {
    await createTargets({ targets });
    toast.add({
      severity: "success",
      summary: "Targets added",
      detail: "Targets have been added successfully",
      life: 5000,
    });
    emit("targets-added");
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 5000,
    });
  }
};

//target_name, persistence_level, extents x0y0, extents x1y0, extents x0y1, extents x1y1, priority?
const targetInputKeys = computed(() => [
  {
    key: "target_id",
    // VADSTENA has validation for target_id
    regExp: /^[a-zA-Z0-9.\-\u00D8-\u00F6]+$/,
    required: true,
    invalidMessage: "Invalid target id (only alphanumeric characters, ., - and special characters are allowed)",
  },
  {
    key: "extents",
    regExp:
      /^([-+]?\d+(\.\d+)?,[-+]?\d+(\.\d+)?:[-+]?\d+(\.\d+)?,[-+]?\d+(\.\d+)?|full)$/,
    required: true,
    invalidMessage: "Invalid extents (e.g. 0,0:1,1 or +1.0,0:2,3 or full)",
  },
  {
    key: "file_persistence_level",
    // VADSTENA has enum for persistence_level
    regExp: new RegExp(
      "^(" + targetFilePersistenceLevels.value.map((e) => e.value).join("|") + ")$"
    ),
    required: false,
    invalidMessage: "Invalid persistence level (e.g. " + targetFilePersistenceLevels.value.map((e) => e.value).join(", ") + ")",
  },
  {
    key: "priority",
    regExp: /^[0-9]+$/,
    required: false,
    invalidMessage: "Invalid priority (only numbers are allowed)",
  },
]);
targetsInput.value =
  targetInputKeys.value.map((key) => key.key).join(DELIMITER) + "\n" + [props.sceneId, props.targetLayoutId, "001"].join("-") + DELIMITER + "full";

const validateTargetInput = (targetsInput: string) => {
  let isValid = true;
  const input = targetsInput.split("\n");
  const targetIds: string[] = [];
  const extents: string[] = [];
  errorMessage.value = "";
  for (let i = 0; i < input.length; i++) {
    // skip the first line if it is the header
    if (input[i] === targetInputKeys.value.map((key) => key.key).join(DELIMITER)) {
      continue;
    }
    // skip empty lines
    if (input[i].trim() === "") {
      continue;
    }

    const targetInput = input[i].split(DELIMITER);
    targetInputKeys.value.forEach((key, index) => {
      // do not validate if already invalid
      if (!isValid) {
        return;
      }
      // skip if the key is not required and the value is not provided or empty
      if (!key.required && (targetInput[index] === undefined || targetInput[index] === "")) {
        return;
      }
      // check if the value is empty and the key is required
      if (!key.regExp.test(targetInput[index])) {
        isValid = false;
        errorMessage.value = key.invalidMessage;
      }
    });

    // check duplicates of ids
    if (targetIds.includes(targetInput[0])) {
      isValid = false;
      errorMessage.value = "Duplicate target id";
      break;
    }
    targetIds.push(targetInput[0]);
    // check duplicates of extents
    if (extents.includes(targetInput[1])) {
      isValid = false;
      errorMessage.value = "Duplicate extents";
      break;
    }
    extents.push(targetInput[1]);
  }

  return isValid;
};

const parseTargetInput = (
  targetsInput: string
): MutationCreateTargetsArgs["targets"] => {
  const input = targetsInput.split("\n");
  const targets: MutationCreateTargetsArgs["targets"] = [];
  for (let i = 0; i < input.length; i++) {
    // skip the first line if it is the header
    if (input[i] === targetInputKeys.value.map((key) => key.key).join(DELIMITER)) {
      continue;
    }
    // skip empty lines
    if (input[i].trim() === "") {
      continue;
    }

    const targetInput = input[i].split(DELIMITER);
    const target = {
      id: targetInput[0],
      targetLayoutId: props.targetLayoutId,
      extents: [targetInput[1]].join(","),
      ...(targetInput[2] ? { filePersistenceLevel: targetInput[2] } : {}),
      ...(targetInput[3] ? { priority: parseInt(targetInput[3] || "0", 10) } : {}),
    };
    targets.push(target);
  }
  return targets;
};
</script>
