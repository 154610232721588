import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-red-500" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from "vee-validate";

// props
import { useTargets } from "@/composables/useTargets";
import { computed, ref } from "vue";
import { MutationCreateTargetsArgs } from "@/gql/graphql";
import { useToast } from "primevue/usetoast";

// composables usage
const DELIMITER = ";";

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneAddTargetsForm',
  props: {
  sceneId: {
    type: String,
    required: true,
  },
  targetLayoutId: {
    type: Number,
    required: true,
  },
},
  emits: ["targets-added"],
  setup(__props, { emit: __emit }) {

const props = __props;

// composables
const { createTargets, getTargetFilePersistenceLevels } = useTargets();
const toast = useToast();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();

// refs
const targetsInput = ref("");
const errorMessage = ref("");

// computed
const validInput = computed(() => validateTargetInput(targetsInput.value));

// emits
const emit = __emit;

// methods
const onSubmit = async () => {
  const targets = parseTargetInput(targetsInput.value);
  try {
    await createTargets({ targets });
    toast.add({
      severity: "success",
      summary: "Targets added",
      detail: "Targets have been added successfully",
      life: 5000,
    });
    emit("targets-added");
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 5000,
    });
  }
};

//target_name, persistence_level, extents x0y0, extents x1y0, extents x0y1, extents x1y1, priority?
const targetInputKeys = computed(() => [
  {
    key: "target_id",
    // VADSTENA has validation for target_id
    regExp: /^[a-zA-Z0-9.\-\u00D8-\u00F6]+$/,
    required: true,
    invalidMessage: "Invalid target id (only alphanumeric characters, ., - and special characters are allowed)",
  },
  {
    key: "extents",
    regExp:
      /^([-+]?\d+(\.\d+)?,[-+]?\d+(\.\d+)?:[-+]?\d+(\.\d+)?,[-+]?\d+(\.\d+)?|full)$/,
    required: true,
    invalidMessage: "Invalid extents (e.g. 0,0:1,1 or +1.0,0:2,3 or full)",
  },
  {
    key: "file_persistence_level",
    // VADSTENA has enum for persistence_level
    regExp: new RegExp(
      "^(" + targetFilePersistenceLevels.value.map((e) => e.value).join("|") + ")$"
    ),
    required: false,
    invalidMessage: "Invalid persistence level (e.g. " + targetFilePersistenceLevels.value.map((e) => e.value).join(", ") + ")",
  },
  {
    key: "priority",
    regExp: /^[0-9]+$/,
    required: false,
    invalidMessage: "Invalid priority (only numbers are allowed)",
  },
]);
targetsInput.value =
  targetInputKeys.value.map((key) => key.key).join(DELIMITER) + "\n" + [props.sceneId, props.targetLayoutId, "001"].join("-") + DELIMITER + "full";

const validateTargetInput = (targetsInput: string) => {
  let isValid = true;
  const input = targetsInput.split("\n");
  const targetIds: string[] = [];
  const extents: string[] = [];
  errorMessage.value = "";
  for (let i = 0; i < input.length; i++) {
    // skip the first line if it is the header
    if (input[i] === targetInputKeys.value.map((key) => key.key).join(DELIMITER)) {
      continue;
    }
    // skip empty lines
    if (input[i].trim() === "") {
      continue;
    }

    const targetInput = input[i].split(DELIMITER);
    targetInputKeys.value.forEach((key, index) => {
      // do not validate if already invalid
      if (!isValid) {
        return;
      }
      // skip if the key is not required and the value is not provided or empty
      if (!key.required && (targetInput[index] === undefined || targetInput[index] === "")) {
        return;
      }
      // check if the value is empty and the key is required
      if (!key.regExp.test(targetInput[index])) {
        isValid = false;
        errorMessage.value = key.invalidMessage;
      }
    });

    // check duplicates of ids
    if (targetIds.includes(targetInput[0])) {
      isValid = false;
      errorMessage.value = "Duplicate target id";
      break;
    }
    targetIds.push(targetInput[0]);
    // check duplicates of extents
    if (extents.includes(targetInput[1])) {
      isValid = false;
      errorMessage.value = "Duplicate extents";
      break;
    }
    extents.push(targetInput[1]);
  }

  return isValid;
};

const parseTargetInput = (
  targetsInput: string
): MutationCreateTargetsArgs["targets"] => {
  const input = targetsInput.split("\n");
  const targets: MutationCreateTargetsArgs["targets"] = [];
  for (let i = 0; i < input.length; i++) {
    // skip the first line if it is the header
    if (input[i] === targetInputKeys.value.map((key) => key.key).join(DELIMITER)) {
      continue;
    }
    // skip empty lines
    if (input[i].trim() === "") {
      continue;
    }

    const targetInput = input[i].split(DELIMITER);
    const target = {
      id: targetInput[0],
      targetLayoutId: props.targetLayoutId,
      extents: [targetInput[1]].join(","),
      ...(targetInput[2] ? { filePersistenceLevel: targetInput[2] } : {}),
      ...(targetInput[3] ? { priority: parseInt(targetInput[3] || "0", 10) } : {}),
    };
    targets.push(target);
  }
  return targets;
};

return (_ctx: any,_cache: any) => {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_unref(Form), { onSubmit: onSubmit }, {
    default: _withCtx(() => [
      _createVNode(_component_Textarea, {
        class: "w-full",
        rows: "10",
        modelValue: targetsInput.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((targetsInput).value = $event))
      }, null, 8, ["modelValue"]),
      (!validInput.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-warning" }, null, -1)),
              _createTextVNode(" Invalid input - " + _toDisplayString(errorMessage.value), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("p", { class: "text-green-500" }, [
              _createElementVNode("i", { class: "fa fa-check" }),
              _createTextVNode(" Valid input")
            ], -1)
          ]))),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          "data-cy": "sceneAddTargetsForm__addTargets",
          label: `Add targets`,
          type: "submit",
          icon: "fa-solid fa-trowel",
          class: "p-button-success mr-2 mb-2 mlwn-button-submit",
          disabled: !validInput.value
        }, null, 8, ["disabled"])
      ])
    ]),
    _: 1
  }))
}
}

})