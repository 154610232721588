<template>
  <div class="grid">
    <div class="col-12">
      <Form
        v-if="formReady"
        :initial-values="initialValues"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <DropdownWithValidation
          field-class="w-full"
          name="clusterId"
          label="Cluster"
          :options="clusters"
          optionValue="id"
          optionLabel="name"
          placeholder="Select cluster"
          class="w-full"
        >
        </DropdownWithValidation>

        <InputNumberWithValidation
          v-if="showFields.gsd"
          name="gsd"
          label="GSD"
          placeholder="GSD"
          :min-fraction-digits="3"
          field-class="w-full"
          class="w-full"
        ></InputNumberWithValidation>

        <InputNumberWithValidation
          v-if="showFields.outputPointDensity"
          name="outputPointDensity"
          label="Output point density"
          placeholder="Output point density"
          field-class="w-full"
          class="w-full"
        ></InputNumberWithValidation>

        <InputNumberWithValidation
          v-if="showFields.outputResolution"
          name="outputResolution"
          label="Output resolution"
          placeholder="Output resolution"
        ></InputNumberWithValidation>

        <InputNumberWithValidation
          v-if="showFields.meshEdgeLength"
          name="meshEdgeLength"
          label="Mesh edge length"
          placeholder="Mesh edge length"
        ></InputNumberWithValidation>

        <InputNumberWithValidation
          v-if="showFields.texelSize"
          name="texelSize"
          label="Texel size"
          placeholder="Texel size"
        ></InputNumberWithValidation>

        <InputJSONWithValidation
          name="options"
          label="Options"
          ref="optionsRef"
        ></InputJSONWithValidation>

        <div class="flex flex-row flex-wrap justify-content-end">
          <Button
            v-if="scene"
            data-cy="scenePopulationForm__startPopulation"
            :label="`Start processing (${scene.vadstenaPipeline})`"
            type="submit"
            icon="fa-solid fa-trowel"
            class="p-button-success mr-2 mb-2 mlwn-button-submit"
          >
          </Button>
          <!-- <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" /> -->
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from "vee-validate";
import * as yup from "yup";
import { computed, Ref, ref, watch } from "vue";

import { useScenes } from "@/composables/useScenes";
const { updateScene, getScene, getScenes } = useScenes();
import { useToast } from "primevue/usetoast";
const toast = useToast();

// clusters
import { useClusters } from "@/composables/useClusters";
const { getClusters } = useClusters();
const refClusters = ref({});
const { data: clusters } = getClusters(refClusters);

const props = defineProps({
  sceneId: {
    required: true,
    type: String,
  },
});

const { scene } = getScene(props.sceneId);

const formReady = ref(false);
const optionsRef = ref<InstanceType<typeof InputJSONWithValidation> | null>(
  null
);

import InputNumberWithValidation from "@/components/UI/Form/components/InputNumberWithValidation.vue";
import DropdownWithValidation from "@/components/UI/Form/components/DropdownWithValidation.vue";
import {
  defaultSceneAttributes,
  defaultSceneOptions,
  SceneAttributes,
  VadstenaPipelineType,
} from "@/components/Helpers/PipelineConfigTemplates";
import InputJSONWithValidation from "@/components/UI/Form/components/InputJSONWithValidation.vue";

/**
 * Validation setup
 */
let schema = yup.object({});

const initialValues: Ref<Partial<Record<SceneAttributes, number>>> = ref({});

// Function
const onSubmit = (values: any) => {
  onUpdateScene(values);
};

// handleSubmit(onSubmit);

const showFieldsResolver = (attributeName: SceneAttributes) => {
  return (
    scene.value &&
    defaultSceneAttributes[
      scene.value.vadstenaPipeline as VadstenaPipelineType
    ].some((x) => x.name === attributeName)
  );
};

const showFields = computed(() => ({
  gsd: showFieldsResolver(SceneAttributes.gsd),
  outputPointDensity: showFieldsResolver(SceneAttributes.outputPointDensity),
  outputResolution: showFieldsResolver(SceneAttributes.outputResolution),
  meshEdgeLength: showFieldsResolver(SceneAttributes.meshEdgeLength),
  texelSize: showFieldsResolver(SceneAttributes.texelSize),
}));

watch(scene, () => {
  if (scene.value) {
    let schemeObject: any = {
      clusterId: yup.string().required().label("Cluster"),
      options: yup.array().required().label("Population config"),
    };
    defaultSceneAttributes[
      scene.value.vadstenaPipeline as VadstenaPipelineType
    ].forEach((x) => {
      initialValues.value[x.name] = x.value;
      schemeObject[x.name] = yup.number().optional().label(x.name);
    });
    schema = yup.object(schemeObject);

    formReady.value = true;
  }
});

watch(optionsRef, () => {
  if (optionsRef.value) {
    const defaultOptions =
      defaultSceneOptions[scene.value.vadstenaPipeline as VadstenaPipelineType];
    optionsRef.value.setValue(defaultOptions);
  }
});

const onUpdateScene = async (values: any) => {
  try {
    const res = await updateScene({
      updateSceneId: props.sceneId,
      requestToVolumeMounting: true,
      ...values,
    });

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully created",
        life: 3000,
      });
      // props.afterSuccessCb()
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while creating",
        life: 3000,
      });
      // props.afterErrorCb()
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while creating. " + err?.message,
      life: 3000,
    });
    console.error(err);
    // props.afterErrorCb(err)
  }
};
</script>
