<template>
  <span>
    <Button 
      data-cy="targetsAdd" 
      size="small"
      severity="sucess" 
      icon="fa-solid fa-plus" 
      label="Add targets" 
      @click="visible = true" 
      v-tooltip.bottom="'Add targets'" />
    <Dialog v-model:visible="visible" modal :header="'Create new scene targets'" :style="{ width: '50vw' }">
        <template #default>
          <SceneAddTargetsForm :scene-id="props.sceneId" :target-layout-id="props.targetLayoutId" @targets-added="onTargetsAdded" />
        </template>  
    </Dialog>
  </span>
</template>

<script setup lang="ts">
import SceneAddTargetsForm from '../SceneStatusViews/components/SceneAddTargetsForm.vue';
import { ref } from 'vue';

const props = defineProps({
  sceneId: {
    type: String,
    required: true
  },
  targetLayoutId: {
    type: Number,
    required: true
  }
})

// refs
const visible = ref(false);

// methods
const onTargetsAdded = () => {
  visible.value = false;
}

</script>