<template>
  <div class="flex align-items-center justify-content-end gap-1">
    <SplitButton
      data-cy="sceneActionButton__expandActions"
      ref="actionSplitButton"
      class="w-full text-align-left"
      @click="openDropdown"
      :icon="getIcon()"
      :disabled="getDisabled()"
      :model="getItems()"
      :label="getLabel()"
      :severity="getSeverity()"
    ></SplitButton>

    <Dialog
      ref="diluteDialog"
      v-model:visible="diluteDialogVisible"
      modal
      :header="'Create new diluted scene from ' + scene?.name"
      :style="{ width: '50vw' }"
    >
      <template #default>
        <DilutionForm
          :scene="scene"
          :saveCb="
            () => {
              diluteDialogVisible = false;
            }
          "
        ></DilutionForm>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
// import
import { Scene, SceneStatusAction, SceneStatusAlias } from "@/gql/graphql";
import { PropType, ref, watch } from "vue";
import { useScenes } from "@/composables/useScenes";
const { updateScene } = useScenes();
import { MenuItem } from "primevue/menuitem";
import Dialog from "primevue/dialog";
import DilutionForm from "@/components/Admin/Project/Scene/PopulationForms/DilutionForm.vue";

// state

const actionSplitButton = ref();
const diluteDialog = ref();
const diluteDialogVisible = ref(false);
const splitButtonDisabled = ref(false);

const requestLabels: Record<
  string,
  {
    label: string;
    icon: string;
    confirm: boolean;
  }
> = {
  requestToPopulating: {
    label: "Start population",
    icon: "fa fa-fw fa-play",
    confirm: false,
  },
  requestToArchive: {
    label: "Archive scene",
    icon: "fa fa-fw fa-archive",
    confirm: true,
  },
  requestToCancelPopulation: {
    label: "Cancel population",
    icon: "fa fa-fw fa-stop",
    confirm: true,
  },
  requestToCancelScene: {
    label: "Cancel scene",
    icon: "fa fa-fw fa-stop",
    confirm: true,
  },
  requestToInputQcFailed: {
    label: "Set Input QC failed",
    icon: "fa fa-fw fa-flag",
    confirm: false,
  },
  requestToInputQcPassed: {
    label: "Set Input QC passed",
    icon: "fa fa-fw fa-flag",
    confirm: false,
  },
  requestToInputQcReview: {
    label: "Revert to Input QC review",
    icon: "fa fa-fw fa-flag",
    confirm: false,
  },
  requestToPurge: {
    label: "Purge scene",
    icon: "fa fa-fw fa-trash",
    confirm: true,
  },
  requestToVolumeUnmounting: {
    label: "Unmount volume",
    icon: "fa fa-fw fa-stop",
    confirm: false,
  },
};

// component

const props = defineProps({
  scene: {
    required: true,
    type: Object as PropType<Scene>,
  },
  showOpenButton: {
    required: false,
    type: Boolean,
    default: true,
  },
});

// composables
import { useConfirm } from "primevue/useconfirm";
const confirm = useConfirm();

watch(
  () => props.scene,
  () => {
    splitButtonDisabled.value = false;
  }
);

const emit = defineEmits(["scene-updated"]);

const getItems = (): MenuItem[] => {
  let items: MenuItem[] = [];

  if (props.showOpenButton) {
    items.push({
      label: "Open detail",
      icon: "fa fa-fw fa-search",
      to: {
        name: "project-scene-detail",
        params: {
          sceneId: props.scene.id,
          projectId: props.scene.project.id,
        },
      },
    });
  }

  // special dialog acitons
  if (props.scene.status.alias === SceneStatusAlias.New) {
    items.push({
      label: "Start population",
      icon: "fa fa-fw fa-play",
      to: {
        name: "project-scene-detail",
        params: {
          sceneId: props.scene.id,
          projectId: props.scene.project.id,
        },
      },
    });
    items.push({
      label: "Configure ingest",
      icon: "fa fa-fw fa-pencil",
      to: {
        name: "update-scene",
        params: {
          sceneId: props.scene.id,
          projectId: props.scene.project.id,
        },
      },
    });
  }

  // special dialog acitons
  if (props.scene.status.alias === SceneStatusAlias.InputQcPassed) {
    items.push({
      label: 'Configure ingest',
      icon: 'fa fa-fw fa-pencil',
      to: {
        name: "project-scene-detail",
        params: {
          sceneId: props.scene.id,
          projectId: props.scene.project.id,
        },
      },
    });
  }

  // special dialog acitons
  if (props.scene.status.alias === SceneStatusAlias.InputQcPassed) {
    items.push({
      label: 'Configure processing',
      icon: 'fa fa-fw fa-play',
      to: {
        name: 'project-scene-detail',
        params: { 
          sceneId: props.scene.id,
          projectId: props.scene.project.id 
        }
      }
    })
  }

  if ([SceneStatusAlias.InputQcPassed, SceneStatusAlias.InputQcFailed, SceneStatusAlias.InputQcReview].includes(props.scene.status.alias)) {
    items.push({
      label: "Dilute scene",
      icon: "fa fa-fw fa-scissors",
      command: () => {
        diluteDialogVisible.value = true;
      },
    });
  }

  props.scene.statusActions
    .filter((action) => action.onRequest)
    .forEach((action) => {
      items.push({
        label:
          requestLabels[action.attributeName]?.label ||
          "Set " + action.attributeName,
        icon:
          requestLabels[action.attributeName]?.icon ||
          "fa fa-fw fa-arrow-right",
        command: () => {
          if (requestLabels[action.attributeName]?.confirm) {
            // show confirm dialog in primevue
            confirm.require({
              message: "Are you sure you want to proceed? ",
              header: "Confirmation",
              icon: "fa fa-exclamation-triangle",
              accept: () => {
                doSceneAction(action);
              },
              reject: () => {
                // do nothing
              },
            });
          } else {
            doSceneAction(action);
          }
        },
      });
    });

  return items;
};

const doSceneAction = async (action: SceneStatusAction) => {
  splitButtonDisabled.value = true;
  const sceneUpdate = {
    updateSceneId: props.scene.id,
    [action.attributeName]: true,
  };
  const res = await updateScene(sceneUpdate);
  emit("scene-updated");
};

const getIcon = () => {
  return props.scene.automationIdle ? "" : "fa fa-fw fa-hourglass-half";
};

const getDisabled = () => {
  return splitButtonDisabled.value || !props.scene.automationIdle;
};

const getLabel = () => {
  if (props.scene.status.alias === SceneStatusAlias.Populating) {
    return "Populating (" + props.scene.populationType.name + ")";
  }
  if (props.scene.status.alias === SceneStatusAlias.PopulatingCancelling) {
    return "Canceling (" + props.scene.populationType.name + ")";
  }
  return props.scene.automationIdle
    ? props.scene.status.name
    : props.scene.statusPredicted.name;
};

const getTooltip = () => {
  return props.scene.automationIdle
    ? props.scene.status.name
    : props.scene.statusPredicted.name;
};

const getSeverity = () => {
  switch (
    props.scene.automationIdle
      ? props.scene.status.alias
      : props.scene.statusPredicted.alias
  ) {
    // case SceneStatusAlias.New:
    //   return 'success'
    // case SceneStatusAlias.InputQcReview:
    //   return 'warning'
    // case SceneStatusAlias.InputQcFailed:
    //   return 'warning'
    // case SceneStatusAlias.InputQcPassed:
    //   return 'warning'
    // case SceneStatusAlias.Canceled:
    //   return 'secondary'
    // case SceneStatusAlias.VolumeUnmountingFailed:
    //   return 'danger'
    // case SceneStatusAlias.FailedArchived:
    //   return 'secondary'
    // case SceneStatusAlias.PassedArchived:
    //   return 'secondary'
    default:
      return "info";
  }
};

const openDropdown = () => {
  if (actionSplitButton.value) {
    const dropdownButton = actionSplitButton.value.$el.querySelector(
      ".p-splitbutton-menubutton"
    );
    dropdownButton.click();
  }
};
</script>
