import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from 'vee-validate';
import { TargetUpdateType } from '@/components/Helpers/VadstenaClusterAPIEnums';
import InputNumberWithValidation from '@/components/UI/Form/components/InputNumberWithValidation.vue';
import { useTargets } from '@/composables/useTargets';
import { useToast } from 'primevue/usetoast';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';
import { TargetUpdateInput } from '@/gql/graphql';


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetUpdateForm',
  props: {
  targetsIds: {
    type: Array,
    required: true
  },
  type: {
    type: String,
    required: true
  }
},
  emits: ['targets-updated'],
  setup(__props, { emit: __emit }) {

const { updateTargets, getTargetFilePersistenceLevels } = useTargets()
const toast = useToast();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();

const props = __props

const emit = __emit;

const onSubmit = async (values: any) => {
  const targets = props.targetsIds.map((targetId: any) => ({
    id: targetId,
    ...values
  } as TargetUpdateInput))
  try {
    await updateTargets({ targets })
    toast.add({ severity: 'success', summary: 'Success', detail: "Targets updated", life: 3000 })
  } catch (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: "Cannot update targets", life: 3000 })
  }
  emit('targets-updated');
}


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_unref(Form), { onSubmit: onSubmit }, {
    default: _withCtx(() => [
      (props.type === _unref(TargetUpdateType).PERSISTENCE_LEVEL)
        ? (_openBlock(), _createBlock(DropdownWithValidation, {
            key: 0,
            label: "Persistence level",
            name: "filePersistenceLevel",
            options: _unref(targetFilePersistenceLevels).map((e)=>e.value).map((key) => ({
        label: key,
        value: key
      })),
            optionLabel: "label",
            optionValue: "value",
            placeholder: "Select persistence level"
          }, null, 8, ["options"]))
        : _createCommentVNode("", true),
      (props.type === _unref(TargetUpdateType).PRIORITY)
        ? (_openBlock(), _createBlock(InputNumberWithValidation, {
            key: 1,
            name: "priority",
            label: "Priority",
            placeholder: "Priority",
            defaultValue: "0",
            min: 0,
            max: 100,
            decimal: 0
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          severity: "success",
          icon: "fa-solid fa-check",
          label: "Update",
          size: "small",
          type: "submit"
        })
      ])
    ]),
    _: 1
  }))
}
}

})