import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import SceneTargets from './SceneTargets.vue';

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneProcessing',
  props: {
    sceneId: {}
  },
  setup(__props: any) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SceneTargets, {
        sceneId: props.sceneId
      }, null, 8, ["sceneId"])
    ])
  ]))
}
}

})