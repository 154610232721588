import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]

import { toRef } from 'vue';
import { useField } from 'vee-validate';
import InfoInputGroup from '../InfoInputGroup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputNumberWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  minFractionDigits: {
    type: Number,
    required: false
  },
  maxFractionDigits: {
    type: Number,
    required: false
  },
  min: {
    type: Number,
    required: false
  },
  max: {
    type: Number,
    required: false
  }
},
  setup(__props) {

const props = __props;

const { errorMessage, value } = useField(toRef(props, 'name'));

return (_ctx: any,_cache: any) => {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", __props.fieldClass])
  }, [
    _createVNode(InfoInputGroup, {
      "hint-content": __props.hintContent,
      "hint-title": __props.hintTitle
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          "aria-describedby": `${__props.name}-help`,
          disabled: __props.disabled,
          class: _normalizeClass({ 'p-invalid': _unref(errorMessage), 'w-full': true }),
          minFractionDigits: __props.minFractionDigits ?? undefined,
          maxFractionDigits: __props.maxFractionDigits ?? undefined,
          min: __props.min ?? undefined,
          max: __props.max ?? undefined,
          type: __props.type
        }, null, 8, ["modelValue", "aria-describedby", "disabled", "class", "minFractionDigits", "maxFractionDigits", "min", "max", "type"]),
        _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_1)
      ]),
      _: 1
    }, 8, ["hint-content", "hint-title"]),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_2),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_3)
  ], 2))
}
}

})