<template>
  <Form @submit="onSubmit">
    <DropdownWithValidation
      v-if="props.type === TargetUpdateType.PERSISTENCE_LEVEL"
      label="Persistence level"
      name="filePersistenceLevel"
      :options="targetFilePersistenceLevels.map((e)=>e.value).map((key) => ({
        label: key,
        value: key
      }))"
      optionLabel="label"
      optionValue="value"
      placeholder="Select persistence level"
    ></DropdownWithValidation>
    <InputNumberWithValidation
      v-if="props.type === TargetUpdateType.PRIORITY"
      name="priority"
      label="Priority"
      placeholder="Priority"
      defaultValue="0"
      :min="0"
      :max="100"
      :decimal="0"
    ></InputNumberWithValidation>
    <div class="flex flex-row flex-wrap justify-content-end">
      <Button
        severity="success"
        icon="fa-solid fa-check"
        label="Update"
        size="small"
        type="submit"
      ></Button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { Form } from 'vee-validate';
import { TargetUpdateType } from '@/components/Helpers/VadstenaClusterAPIEnums';
import InputNumberWithValidation from '@/components/UI/Form/components/InputNumberWithValidation.vue';
import { useTargets } from '@/composables/useTargets';
import { useToast } from 'primevue/usetoast';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';
import { TargetUpdateInput } from '@/gql/graphql';

const { updateTargets, getTargetFilePersistenceLevels } = useTargets()
const toast = useToast();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();

const props = defineProps({
  targetsIds: {
    type: Array,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['targets-updated']);

const onSubmit = async (values: any) => {
  const targets = props.targetsIds.map((targetId: any) => ({
    id: targetId,
    ...values
  } as TargetUpdateInput))
  try {
    await updateTargets({ targets })
    toast.add({ severity: 'success', summary: 'Success', detail: "Targets updated", life: 3000 })
  } catch (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: "Cannot update targets", life: 3000 })
  }
  emit('targets-updated');
}

</script>