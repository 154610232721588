import gql from "graphql-tag";

export const GET_TARGETS = gql(`
  query GetTargets($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
    getTargets(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
      targets {
        id
        name
        targetLayout {
          id
          name
        }
        priority
        extents
        notes
        statusAlias
        filePersistenceLevel
        products {
          id
          status {
            alias
            name
          }
        }
        error
        requestToCreating
        requestToPurge
        requestToForcePurge
        requestToProductSyncing
        automationIdle
        statusPredicted {
          alias
          name
        }
        statusActions {
          attributeName
          onRequest
          statusAlias
        }
        status {
          alias
          name
        }
      }
      pageInfo {
        total
        limit
        offset
      }
    }
  }
`)


export const GET_TARGETS_IDS = gql(`
  query GetTargetsIds($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
    getTargets(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
      targets {
        id
      }
      pageInfo {
        total
        limit
        offset
      }
    }
  }
`)

export const GET_TARGET_FILE_PERSISTENCE_LEVELS = gql(`
  query GetTargetFilePersistenceLevels {
    targetFilePersistenceLevels {
      value
      label
    }
  }
`)

export const GET_TARGET_STATUSES = gql(`
  query GetTargetStatuses {
    getTargetStatuses {
      alias
      name
    }
  }
`)
