<template>
  <span>
    <SplitButton 
      data-cy="targetUpdate__expandActions" 
      ref="splitButton" 
      class="text-align-left"
      @click="openDropdown" 
      label="Edit"
      size="small"
      icon="fa-solid fa-pencil" 
      :disabled="!props.targetsIds || props.targetsIds.length === 0" 
      tooltip="'Edit targets'"
      :model="items"
      severity="warning"
    >
    </SplitButton>
    <Dialog v-model:visible="visible" modal :header="'Create new scene targets'" :style="{ width: '50vw' }">
        <template #default>
          <TargetUpdateForm
            :targets-ids="props.targetsIds"
            :type="targetsUpdateType" 
            @targets-updated="onTargetsUpdated"
          />
        </template>  
    </Dialog>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TargetUpdateForm from '../Target/TargetUpdateForm.vue';
import { TargetUpdateType } from '@/components/Helpers/VadstenaClusterAPIEnums';

// composables
import { useTargets } from '@/composables/useTargets';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';

// composables usage
const { updateTargets } = useTargets();
const confirm = useConfirm();
const toast = useToast();

const props = defineProps({
  targetsIds: {
    type: Array,
    required: true
  }
})

// refs
const targetsUpdateType = ref(TargetUpdateType.PERSISTENCE_LEVEL)
const visible = ref(false)
const splitButton = ref()
const items = [
  {
    label: 'Set persistence level',
    icon: 'fa fa-fw fa-database',
    command: () => {
      targetsUpdateType.value = TargetUpdateType.PERSISTENCE_LEVEL;
      visible.value = true;
    }
  },
  {
    label: 'Set priority',
    icon: 'fa fa-fw fa-list',
    command: () => {
      targetsUpdateType.value = TargetUpdateType.PRIORITY;
      visible.value = true;
    }
  },
  {
    label: 'Request to Creating',
    icon: 'fa fa-fw fa-check',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToCreating: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to creating', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to creating', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
  {
    label: 'Request to Purge',
    icon: 'fa fa-fw fa-trash',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToPurge: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to purge', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to purge', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
  {
    label: 'Request to Force Purge',
    icon: 'fa fa-fw fa-trash',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToForcePurge: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to force purge', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to force purge', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
]

// emits
const emit = defineEmits(['targets-updated']);

// methods
const onTargetsUpdated = () => {
  visible.value = false;
  emit('targets-updated');
}

const openDropdown = () => {
  if (splitButton.value) {

    const dropdownButton = splitButton.value.$el.querySelector('.p-splitbutton-menubutton');
    dropdownButton.click();
  }

}
</script>