import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

import { toRef } from 'vue';
import { useField } from 'vee-validate';
import Dropdown from 'primevue/dropdown';
import InfoInputGroup from '../InfoInputGroup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  optionLabel: {
    required: false,
    default: 'label'
  },
  optionValue: {
    required: false,
    default: 'value'
  },
  optionGroupLabel: {
    type: String,
    required: false,
  },
  optionGroupChildren: {
    type: String,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Please select an option'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showClear: {
    type: Boolean,
    default: true
  },
  filter: {
    type: Boolean,
    default: true
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props) {

const props = __props;

const { errorMessage, value } = useField(toRef(props, 'name'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", __props.fieldClass])
  }, [
    _createVNode(InfoInputGroup, {
      "hint-content": __props.hintContent,
      "hint-title": __props.hintTitle,
      "float-label-class": !__props.minimize
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Dropdown), {
          "scroll-height": "400px",
          class: "w-full",
          "aria-describedby": `${__props.name}-help`,
          label: __props.label,
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          options: __props.options,
          optionLabel: __props.optionLabel,
          optionValue: __props.optionValue,
          placeholder: __props.placeholder,
          loading: __props.loading,
          disabled: __props.disabled,
          showClear: __props.showClear,
          filter: __props.filter,
          optionGroupLabel: __props.optionGroupLabel,
          optionGroupChildren: __props.optionGroupChildren
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dropdown-content")
          ]),
          _: 3
        }, 8, ["aria-describedby", "label", "modelValue", "options", "optionLabel", "optionValue", "placeholder", "loading", "disabled", "showClear", "filter", "optionGroupLabel", "optionGroupChildren"]),
        (!__props.minimize)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: __props.name
            }, _toDisplayString(__props.label), 9, _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["hint-content", "hint-title", "float-label-class"]),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_2)
  ], 2))
}
}

})