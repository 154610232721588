import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from "vee-validate";
import * as yup from "yup";
import { computed, Ref, ref, watch } from "vue";

import { useScenes } from "@/composables/useScenes";
import { useToast } from "primevue/usetoast";
import { useClusters } from "@/composables/useClusters";
import InputNumberWithValidation from "@/components/UI/Form/components/InputNumberWithValidation.vue";
import DropdownWithValidation from "@/components/UI/Form/components/DropdownWithValidation.vue";
import {
  defaultSceneAttributes,
  defaultSceneOptions,
  SceneAttributes,
  VadstenaPipelineType,
} from "@/components/Helpers/PipelineConfigTemplates";
import InputJSONWithValidation from "@/components/UI/Form/components/InputJSONWithValidation.vue";

/**
 * Validation setup
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneProcessingForm',
  props: {
  sceneId: {
    required: true,
    type: String,
  },
},
  setup(__props) {

/**
 * Load validation references, router, vue
 */
const { updateScene, getScene, getScenes } = useScenes();
const toast = useToast();

// clusters
const { getClusters } = useClusters();
const refClusters = ref({});
const { data: clusters } = getClusters(refClusters);

const props = __props;

const { scene } = getScene(props.sceneId);

const formReady = ref(false);
const optionsRef = ref<InstanceType<typeof InputJSONWithValidation> | null>(
  null
);

let schema = yup.object({});

const initialValues: Ref<Partial<Record<SceneAttributes, number>>> = ref({});

// Function
const onSubmit = (values: any) => {
  onUpdateScene(values);
};

// handleSubmit(onSubmit);

const showFieldsResolver = (attributeName: SceneAttributes) => {
  return (
    scene.value &&
    defaultSceneAttributes[
      scene.value.vadstenaPipeline as VadstenaPipelineType
    ].some((x) => x.name === attributeName)
  );
};

const showFields = computed(() => ({
  gsd: showFieldsResolver(SceneAttributes.gsd),
  outputPointDensity: showFieldsResolver(SceneAttributes.outputPointDensity),
  outputResolution: showFieldsResolver(SceneAttributes.outputResolution),
  meshEdgeLength: showFieldsResolver(SceneAttributes.meshEdgeLength),
  texelSize: showFieldsResolver(SceneAttributes.texelSize),
}));

watch(scene, () => {
  if (scene.value) {
    let schemeObject: any = {
      clusterId: yup.string().required().label("Cluster"),
      options: yup.array().required().label("Population config"),
    };
    defaultSceneAttributes[
      scene.value.vadstenaPipeline as VadstenaPipelineType
    ].forEach((x) => {
      initialValues.value[x.name] = x.value;
      schemeObject[x.name] = yup.number().optional().label(x.name);
    });
    schema = yup.object(schemeObject);

    formReady.value = true;
  }
});

watch(optionsRef, () => {
  if (optionsRef.value) {
    const defaultOptions =
      defaultSceneOptions[scene.value.vadstenaPipeline as VadstenaPipelineType];
    optionsRef.value.setValue(defaultOptions);
  }
});

const onUpdateScene = async (values: any) => {
  try {
    const res = await updateScene({
      updateSceneId: props.sceneId,
      requestToVolumeMounting: true,
      ...values,
    });

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully created",
        life: 3000,
      });
      // props.afterSuccessCb()
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while creating",
        life: 3000,
      });
      // props.afterErrorCb()
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while creating. " + err?.message,
      life: 3000,
    });
    console.error(err);
    // props.afterErrorCb(err)
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (formReady.value)
        ? (_openBlock(), _createBlock(_unref(Form), {
            key: 0,
            "initial-values": initialValues.value,
            "validation-schema": _unref(schema),
            onSubmit: onSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(DropdownWithValidation, {
                "field-class": "w-full",
                name: "clusterId",
                label: "Cluster",
                options: _unref(clusters),
                optionValue: "id",
                optionLabel: "name",
                placeholder: "Select cluster",
                class: "w-full"
              }, null, 8, ["options"]),
              (showFields.value.gsd)
                ? (_openBlock(), _createBlock(InputNumberWithValidation, {
                    key: 0,
                    name: "gsd",
                    label: "GSD",
                    placeholder: "GSD",
                    "min-fraction-digits": 3,
                    "field-class": "w-full",
                    class: "w-full"
                  }))
                : _createCommentVNode("", true),
              (showFields.value.outputPointDensity)
                ? (_openBlock(), _createBlock(InputNumberWithValidation, {
                    key: 1,
                    name: "outputPointDensity",
                    label: "Output point density",
                    placeholder: "Output point density",
                    "field-class": "w-full",
                    class: "w-full"
                  }))
                : _createCommentVNode("", true),
              (showFields.value.outputResolution)
                ? (_openBlock(), _createBlock(InputNumberWithValidation, {
                    key: 2,
                    name: "outputResolution",
                    label: "Output resolution",
                    placeholder: "Output resolution"
                  }))
                : _createCommentVNode("", true),
              (showFields.value.meshEdgeLength)
                ? (_openBlock(), _createBlock(InputNumberWithValidation, {
                    key: 3,
                    name: "meshEdgeLength",
                    label: "Mesh edge length",
                    placeholder: "Mesh edge length"
                  }))
                : _createCommentVNode("", true),
              (showFields.value.texelSize)
                ? (_openBlock(), _createBlock(InputNumberWithValidation, {
                    key: 4,
                    name: "texelSize",
                    label: "Texel size",
                    placeholder: "Texel size"
                  }))
                : _createCommentVNode("", true),
              _createVNode(InputJSONWithValidation, {
                name: "options",
                label: "Options",
                ref_key: "optionsRef",
                ref: optionsRef
              }, null, 512),
              _createElementVNode("div", _hoisted_3, [
                (_unref(scene))
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      "data-cy": "scenePopulationForm__startPopulation",
                      label: `Start processing (${_unref(scene).vadstenaPipeline})`,
                      type: "submit",
                      icon: "fa-solid fa-trowel",
                      class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["initial-values", "validation-schema"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})