<template>
  <div class="grid">
    <div class="col-12">
      <p>
        <Panel>
          <template #header>
            <span>Processing configuration</span>
          </template>

          <SceneProcessingForm :sceneId="props.sceneId" />
        </Panel>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SceneProcessingForm from "./components/SceneProcessingForm.vue";

const props = defineProps({
  sceneId: {
    required: true,
    type: String,
  },
});
</script>

<style lang="scss" scoped>
.code-block {
  overflow: auto;
  border: 1px solid;
  pre {
    padding: 0.5rem;
  }
}
</style>
