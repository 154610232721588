import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import SceneAddTargetsForm from '../SceneStatusViews/components/SceneAddTargetsForm.vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SceneAddTargetsDialog',
  props: {
  sceneId: {
    type: String,
    required: true
  },
  targetLayoutId: {
    type: Number,
    required: true
  }
},
  setup(__props) {

const props = __props

// refs
const visible = ref(false);

// methods
const onTargetsAdded = () => {
  visible.value = false;
}


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", null, [
    _withDirectives(_createVNode(_component_Button, {
      "data-cy": "targetsAdd",
      size: "small",
      severity: "sucess",
      icon: "fa-solid fa-plus",
      label: "Add targets",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = true))
    }, null, 512), [
      [
        _directive_tooltip,
        'Add targets',
        void 0,
        { bottom: true }
      ]
    ]),
    _createVNode(_component_Dialog, {
      visible: visible.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: 'Create new scene targets',
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(SceneAddTargetsForm, {
          "scene-id": props.sceneId,
          "target-layout-id": props.targetLayoutId,
          onTargetsAdded: onTargetsAdded
        }, null, 8, ["scene-id", "target-layout-id"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})