import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex-grow-1" }

import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useScenes } from '@/composables/useScenes';

import { useToast } from 'primevue/usetoast';
import { Create_SceneMutationVariables, PopulationTypeAlias, Scene } from '@/gql/graphql';
import Button from 'primevue/button';

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateScene',
  props: {
  projectId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const { createScene } = useScenes()

const props = __props

const router = useRouter();

const nextPage = (event) => {
  //console.log(event, 'nextpage')
}

const formData = ref({});

const items = [
  { label: 'Scene details', routeName: 'StepOne', step: 1, commandBack: () => { }, command: () => { router.push({ name: 'SensorDefinitions' })}, to: { name: 'StepOne'} },
  { label: 'Sensor definitions', routeName: 'SensorDefinitions', step: 2, commandBack: () => { router.push({ name: 'StepOne' })}, command: () => { router.push({ name: 'InputConfigurations' }) }, to: { name: 'SensorDefinitions'} },  
  { label: 'Input Configurations', routeName: 'InputConfigurations', step: 3, commandBack: () => { router.push({ name: 'SensorDefinitions' })}, command: () => { router.push({ name: 'StepOverview' }) }, to: { name: 'InputConfigurations'} },
  { label: 'Review & Submit', routeName: 'StepOverview', step: 4, commandBack: () => { router.push({ name: 'InputConfigurations' })}, command: () => { }, to: { name: 'StepOverview'}}
];

const activeIndex = ref(items.findIndex(item => router?.currentRoute?.value?.name === item.routeName));

function nextStep(data: any, fromRouteName: String, populationConfig = true, inputVolumesConfiguration = false) {
  if (populationConfig) {
    formData.value.populationConfig = { ...formData.value.populationConfig, ...data }
  } else {
    formData.value = { ...formData.value, ...data }
  }

  // window.localStorage.setItem('mlwn-scene-form', JSON.stringify(formData.value))

  activeIndex.value++;
  items.find(item => item.routeName === fromRouteName)?.command()
}

function complete(data: object) {
  onCreateScene(JSON.parse(JSON.stringify({
    projectId: parseInt(props.projectId),
    populationTypeAlias: PopulationTypeAlias.Ingest,
    ...data
  })))
}

const prevPage = (fromRouteName: String) => {
  activeIndex.value--;
  items.find(item => item.routeName === fromRouteName)?.commandBack()
}

const toast = useToast()

const onCreateScene = async (scene: Create_SceneMutationVariables) => {
  try {
    const res = await createScene(scene)
    
    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      window.localStorage.removeItem('mlwn-scene-form')
      router.push({
        name: 'project-scene-detail',
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

const onCreateSceneExpertMode = async () => {
  router.push({
    name: 'create-scene-expert-mode',
    params: {
      projectId: props.projectId
    }
  })
}


return (_ctx: any,_cache: any) => {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createTextVNode(" Create new scene: ")),
              _createElementVNode("strong", null, _toDisplayString(items[activeIndex.value]?.label), 1)
            ]),
            _createVNode(_unref(Button), {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (onCreateSceneExpertMode())),
              icon: "pi pi-arrow-right",
              label: "Enter Expert mode",
              severity: "danger",
              class: "p-button-rounded p-button-text p-button-sm p-button-outlined flex-none"
            })
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_Steps, {
            model: items,
            activeIndex: activeIndex.value,
            readonly: true
          }, null, 8, ["activeIndex"]),
          _createVNode(_component_Divider, {
            type: "dotted",
            class: "pb-4"
          }),
          _createVNode(_component_router_view, {
            formData: formData.value,
            projectId: __props.projectId,
            onNext: nextStep,
            onPrevPage: _cache[1] || (_cache[1] = ($event: any) => (prevPage($event))),
            onNextPage: _cache[2] || (_cache[2] = ($event: any) => (nextPage($event))),
            onComplete: complete
          }, {
            default: _withCtx(({Component}) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 1
          }, 8, ["formData", "projectId"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})