import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';
import TargetUpdateForm from '../Target/TargetUpdateForm.vue';
import { TargetUpdateType } from '@/components/Helpers/VadstenaClusterAPIEnums';

// composables
import { useTargets } from '@/composables/useTargets';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';

// composables usage

export default /*@__PURE__*/_defineComponent({
  __name: 'TargetUpdateDialog',
  props: {
  targetsIds: {
    type: Array,
    required: true
  }
},
  emits: ['targets-updated'],
  setup(__props, { emit: __emit }) {

const { updateTargets } = useTargets();
const confirm = useConfirm();
const toast = useToast();

const props = __props

// refs
const targetsUpdateType = ref(TargetUpdateType.PERSISTENCE_LEVEL)
const visible = ref(false)
const splitButton = ref()
const items = [
  {
    label: 'Set persistence level',
    icon: 'fa fa-fw fa-database',
    command: () => {
      targetsUpdateType.value = TargetUpdateType.PERSISTENCE_LEVEL;
      visible.value = true;
    }
  },
  {
    label: 'Set priority',
    icon: 'fa fa-fw fa-list',
    command: () => {
      targetsUpdateType.value = TargetUpdateType.PRIORITY;
      visible.value = true;
    }
  },
  {
    label: 'Request to Creating',
    icon: 'fa fa-fw fa-check',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToCreating: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to creating', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to creating', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
  {
    label: 'Request to Purge',
    icon: 'fa fa-fw fa-trash',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToPurge: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to purge', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to purge', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
  {
    label: 'Request to Force Purge',
    icon: 'fa fa-fw fa-trash',
    command: () => {
      confirm.require({
        message: 'Are you sure you want to request the selected targets to purge?',
        header: 'Confirmation',
        icon: 'fa fa-exclamation-triangle',
        accept: async () => {
          try {
            await updateTargets({
              targets: props.targetsIds.map((targetId: any) => ({
                id: targetId,
                requestToForcePurge: true
              }))
            });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Target(s) have been requested to force purge', life: 5000 });
            emit('targets-updated');
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to request targets to force purge', life: 5000 });
          }
        },
        reject: () => {
          // do nothing
        }
      });
    }
  },
]

// emits
const emit = __emit;

// methods
const onTargetsUpdated = () => {
  visible.value = false;
  emit('targets-updated');
}

const openDropdown = () => {
  if (splitButton.value) {

    const dropdownButton = splitButton.value.$el.querySelector('.p-splitbutton-menubutton');
    dropdownButton.click();
  }

}

return (_ctx: any,_cache: any) => {
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_SplitButton, {
      "data-cy": "targetUpdate__expandActions",
      ref_key: "splitButton",
      ref: splitButton,
      class: "text-align-left",
      onClick: openDropdown,
      label: "Edit",
      size: "small",
      icon: "fa-solid fa-pencil",
      disabled: !props.targetsIds || props.targetsIds.length === 0,
      tooltip: "'Edit targets'",
      model: items,
      severity: "warning"
    }, null, 8, ["disabled"]),
    _createVNode(_component_Dialog, {
      visible: visible.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: 'Create new scene targets',
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(TargetUpdateForm, {
          "targets-ids": props.targetsIds,
          type: targetsUpdateType.value,
          onTargetsUpdated: onTargetsUpdated
        }, null, 8, ["targets-ids", "type"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})