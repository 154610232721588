import { ComputedRef, Ref, computed } from 'vue'
import { useMutation, useLazyQuery, useQuery } from '@vue/apollo-composable'
import { GET_TARGET_FILE_PERSISTENCE_LEVELS, GET_TARGET_STATUSES, GET_TARGETS, GET_TARGETS_IDS } from '@/apollo/queries/target'
import { CreateTargetsMutation, CreateTargetsMutationVariables, QueryGetTargetsArgs, PageInfo, Target, TargetFilePersistenceLevel, UpdateTargetsMutation, UpdateTargetsMutationVariables } from '@/gql/graphql'
import { CREATE_TARGETS, UPDATE_TARGETS } from '@/apollo/mutations/target'

export function useTargets() {
  const { mutate: createTargetsMutation } = useMutation(CREATE_TARGETS)
  const { mutate: updateProjectMutation } = useMutation(UPDATE_TARGETS)

  /**
   * @description
   * @param {query} query
   * @return {*}  
   */
  function getTargets(query: Ref<QueryGetTargetsArgs>, pollInterval: number | undefined = 0) {
    const { result, error, loading, load, refetch } = useLazyQuery(GET_TARGETS, query.value, {
      errorPolicy: 'all',
      pollInterval
    })

    const targets: ComputedRef<Target[]> = computed(() => result?.value?.getTargets?.targets ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getTargets?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getTargets?.timestamp ?? null)

    return { targets, error, loading, pageInfo, timestamp, load: () => { 
      load(GET_TARGETS, query.value)
    }, refetch: () => {
      refetch(query.value)
    } }
  }

  /**
   * @description
   * @param {id} sceneId
   * @return {*}  
   */
  function getTargetsIds(query: Ref<QueryGetTargetsArgs>, pollInterval: number | undefined = 0) {
    const { result, error, loading, load, refetch } = useLazyQuery(GET_TARGETS_IDS, query.value, {
      errorPolicy: 'all',
      pollInterval
    })

    const targets: ComputedRef<Target[]> = computed(() => result?.value?.getTargets?.targets ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getTargets?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getTargets?.timestamp ?? null)

    return { targets, error, loading, pageInfo, timestamp, load: () => { 
      load(GET_TARGETS_IDS, query.value)
    }, refetch: () => {
      refetch(query.value)
    } }
  }

  /**
   * @description
   * @param {Project} project
   * @return {*}  {Promise<projectMutationResult>}
   */
  async function createTargets(targets: CreateTargetsMutationVariables): Promise<CreateTargetsMutation['createTargets']> {
    try {
      const res = await createTargetsMutation(targets)
      if(res?.data?.createTargets) {
        return res.data.createTargets
      } else {
        throw new Error('Failed to create targets')
      }
    } catch (err) {
      throw new Error('Failed to create targets')
    }
  }

  /**
   * @description
   * @param {Project} project
   * @return {*}  {Promise<projectMutationResult>}
   */
  async function updateTargets(targets: UpdateTargetsMutationVariables): Promise<UpdateTargetsMutation['updateTargets']> {
    try {
      const res = await updateProjectMutation(targets)
      if(res?.data?.updateTargets) {
        return res.data.updateTargets
      } else {
        throw new Error('Failed to update targets')
      }
    } catch (err) {
      throw new Error('Failed to update targets')
    }
  }

  /**
   * @description
   * @param {query} query
   * @return {*}  
   */
  function getTargetFilePersistenceLevels(pollInterval: number | undefined = 0) {
    const { result, error, loading } = useQuery(GET_TARGET_FILE_PERSISTENCE_LEVELS, {}, {
      errorPolicy: 'all',
      pollInterval
    })

    const targetFilePersistenceLevels: ComputedRef<TargetFilePersistenceLevel[]> = computed(() => result?.value?.targetFilePersistenceLevels ?? [])

    return { targetFilePersistenceLevels, error, loading }
  }

  /**
   * @description
   * @param {query} query
   * @return {*}  
   */
  function getTargetStatuses(pollInterval: number | undefined = 0) {
    const { result, error, loading } = useQuery(GET_TARGET_STATUSES, {}, {
      errorPolicy: 'all',
      pollInterval
    })

    const targetStatuses: ComputedRef<TargetFilePersistenceLevel[]> = computed(() => result?.value?.getTargetStatuses ?? [])

    return { targetStatuses, error, loading }
  }
  
  return { getTargets, getTargetsIds, createTargets, updateTargets, getTargetFilePersistenceLevels, getTargetStatuses }
}
