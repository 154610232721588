import { ComputedRef, computed } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { GET_SCENE, GET_SCENE_STATUSES, GET_SCENES, GET_SERIALIZED_POPULATION_CONFIG, SCENE_ID_EXIST, SerializePopulationConfig } from '@/apollo/queries/scene'
import { Create_SceneMutation, Create_SceneMutationVariables, GetSceneStatusesDocument, PageInfo, QueryGetScenesArgs, Scene, SceneStatus, Update_SceneMutation, Update_SceneMutationVariables }  from '@/gql/graphql'
import { START_SCENE_POPULATION, CREATE_SCENE, UPDATE_SCENE } from '@/apollo/mutations/scene'
import { mutationResult } from './helpers/mutationResult'

export function useScenes() {
  const { mutate: createSceneMutation } = useMutation(CREATE_SCENE)
  const { mutate: populationSceneMutation } = useMutation(START_SCENE_POPULATION)
  const { mutate: updateSceneMutation } = useMutation(UPDATE_SCENE)

  /**
   * @description
   * @param {id} sceneId
   * @return {*}  
   */
  function getScenes(query: QueryGetScenesArgs | {} = {}, pollInterval: number | undefined = 0) {
    const { result, error, loading, refetch } = useQuery(GET_SCENES, query, {
      errorPolicy: 'all',
      pollInterval
    })

    const scenes: ComputedRef<Scene[]> = computed(() => result?.value?.getScenes?.scenes ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getScenes?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getScenes?.timestamp ?? null)

    return { scenes, error, loading, pageInfo, timestamp, refetch }
  }

  /**
   * @description
   * @param {id} sceneId
   * @return {*}  
   */
  function sceneExist(sceneId: string) {
    const { result, error, loading, refetch } = useQuery(SCENE_ID_EXIST, {
      sceneId
    }, {
      errorPolicy: 'all'
    })
    const exist : ComputedRef<Boolean> = computed(() => result?.value?.sceneIdExist ?? null)
    return { exist, error, loading, refetch: () => refetch({sceneId}) }
  }

  /**
   * @description
   * @param {id} sceneId
   * @return {*}  
   */
  function getScene(sceneId: string, pollInterval: number | undefined = 0) {
    const { result, error, loading, refetch } = useQuery(GET_SCENE, {
      sceneId
    }, {
      errorPolicy: 'all',
      pollInterval
    })
    const scene : ComputedRef<Scene> = computed(() => result?.value?.getScene ?? null)
    return { scene, error, loading, refetch: () => refetch({sceneId}) }
  }

  /**
   * @description
   * @param {id} sceneId
   * @return {*}  
   */
  function getSceneStatuses() {
    const { result, error, loading, refetch } = useQuery(GET_SCENE_STATUSES, {
      errorPolicy: 'all',
    })
    const sceneStatuses : ComputedRef<SceneStatus[]> = computed(() => result?.value?.getSceneStatuses ?? null)
    return { sceneStatuses, error, loading, refetch: () => refetch() }
  }

  /**
   * @description return serialized population configuration of created scene
   * @param {id} sceneId
   * @return {*}  
   */
  function getSerializedPopulationConfig(sceneId: string) {
    const { result, error, loading, refetch } = useQuery(GET_SERIALIZED_POPULATION_CONFIG, {
      sceneId
    }, {
      errorPolicy: 'all'
    })
    const populationConfig : ComputedRef<string | null> = computed(() => result?.value?.getSerializedPopulationConfig ?? null)
    return { populationConfig, error, loading, refetch: () => refetch({sceneId}) }
  }

  /**
   * @description return serialized population configuration from JSON
   * @param {object} config
   * @return {*}  
   */
  function serializePopulationConfig(config: object) {
    const { result, error, loading, refetch } = useQuery(SerializePopulationConfig, {
      config
    }, {
      errorPolicy: 'all'
    })
    const populationConfigSerialized : ComputedRef<string | null> = computed(() => result?.value?.serializePopulationConfig ?? null)
    return { populationConfigSerialized, error, loading, refetch: (config: object) => refetch({config}) }
  }

  /**
   * @param {Create_SceneMutationVariables} scene
   * @return {*}  {Promise<mutationResult<{ scene: Create_SceneMutation["createScene"] }>>}
   */
  async function createScene(
    scene: Create_SceneMutationVariables
  ): Promise<mutationResult<{ scene: Create_SceneMutation["createScene"] }>> {
    const result: mutationResult<{
      scene: Create_SceneMutation["createScene"];
    }> = {};
    try {
      const res = await createSceneMutation(scene);
      if (res?.data?.createScene) {
        result.success = true;
        result.payload = {
          scene: res?.data.createScene,
        };
      } else {
        result.success = false;
      }
    } catch (err) {
      result.success = false;
      result.error = err;
    }
    return result;
  }

  /**
   * @description
   * @param {Scene} scene
   * @return {*}  {Promise<mutationResult<{ scene: Update_SceneMutation["updateScene"] }>>}
   */
  async function updateScene(
    scene: Update_SceneMutationVariables
  ): Promise<mutationResult<{ scene: Update_SceneMutation["updateScene"] }>> {
    const result : mutationResult<{ scene: Update_SceneMutation["updateScene"] }> = {}
    try {
      const res = await updateSceneMutation(scene)
      if(res?.data?.updateScene) {
        result.success = true
        result.payload = {
          scene: res.data.updateScene
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  async function populateScene(sceneId: string, dmCollocationId: string, outputDmVolumeMountAlias: string, logDmVolumeMountAlias?: string | undefined): Promise<mutationResult>  {
    const result : mutationResult = {}
    try {
      const res = await populationSceneMutation({
        sceneId,
        dmCollocationId,
        outputDmVolumeMountAlias,
        logDmVolumeMountAlias: logDmVolumeMountAlias ? logDmVolumeMountAlias: outputDmVolumeMountAlias
      });
      if (res?.data?.startScenePopulation) {
        result.success = true;
      } else {
        result.success = false;
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result;
  }

  
  return { getScene, getSceneStatuses, sceneExist, getScenes, createScene, updateScene, populateScene, getSerializedPopulationConfig, serializePopulationConfig }
}
