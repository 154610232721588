import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  key: 0,
  for: "id",
  class: "p-error"
}
const _hoisted_6 = { class: "flex align-items-center" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = {
  key: 0,
  class: "text-center font-italic"
}
const _hoisted_9 = { class: "grid" }
const _hoisted_10 = {
  key: 0,
  class: "col-6 col-md-4"
}
const _hoisted_11 = {
  key: 1,
  class: "col-6 col-md-4"
}
const _hoisted_12 = {
  key: 2,
  class: "col-6 col-md-4"
}
const _hoisted_13 = {
  key: 3,
  class: "col-6 col-md-4"
}
const _hoisted_14 = {
  key: 4,
  class: "col-6 col-md-4"
}
const _hoisted_15 = {
  key: 5,
  class: "col-6 col-md-4"
}
const _hoisted_16 = { class: "grid" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "flex w-full" }
const _hoisted_20 = { class: "flex-none" }
const _hoisted_21 = { class: "w-full overflow-y-auto" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "flex justify-content-end" }

import { computed, ref, toRaw, watch } from "vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import {
  Create_SceneMutationVariables,
  PopulationTypeAlias,
} from "@/gql/graphql";
import { useRouter } from "vue-router";
import { useScenes } from "@/composables/useScenes";
import { useToast } from "primevue/usetoast";
import {
  VadstenaPipelineType,
  groupedPipelinePresets,
  defaultPopulationConfig,
} from "@/components/Helpers/PipelineConfigTemplates";

import DataBrowserSelect from "@/components/DataBrowser/DataBrowseSelect.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateSceneExpertMode',
  props: {
  projectId: {
    required: true,
    type: String,
  },
},
  setup(__props) {

const { createScene, sceneExist, serializePopulationConfig } = useScenes();
const router = useRouter();
const toast = useToast();

const props = __props;

const pipelineTypes = ref(groupedPipelinePresets);

const initialValues = ref({
  id: "",
  name: "",
  notes: "",
  idExist: false,
  vadstenaPipeline: pipelineTypes.value[0].items[0].value,
  populationConfig:
    defaultPopulationConfig[pipelineTypes.value[0].items[0].value],
});

const formData = ref(toRaw(initialValues.value));

const {
  populationConfigSerialized,
  loading: loadingSerializedPopulationConfig,
  refetch: refetchSerializedPopulationConfig,
} = serializePopulationConfig(formData.value.populationConfig);

/**
 * Validation setup
 */
const schema = yup.object({
  id: yup.string().min(3).required().label("id"),
  name: yup.string().min(3).required().label("name"),
  label: yup.string().required().label("label"),
  pipelineType: yup.string().required().label("pipelineType"),
  populationConfig: yup.object().required(),
  vadstenaPipeline: yup.string().required(),
});

function onComplete() {
  onCreateScene({
    projectId: parseInt(props.projectId),
    populationTypeAlias: PopulationTypeAlias.Ingest,
    ...toRaw(formData.value),
  });
}

// additional UI components

const showDataBrowseButton = computed(() => ({
  srs: [VadstenaPipelineType.Blk, VadstenaPipelineType.Uav].includes(
    formData.value.vadstenaPipeline
  ),
  dem: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Supermesh,
    VadstenaPipelineType.Uav,
  ].includes(formData.value.vadstenaPipeline),
  imageDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  trajectoryDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  lidarDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  eopsDir: [VadstenaPipelineType.Supermesh].includes(
    formData.value.vadstenaPipeline
  ),
}));
const anyShowDataBrowseButton = computed(() =>
  Object.values(showDataBrowseButton.value).some((v) => v === true)
);

const onSelectLocation = async (data: any, locationType: string) => {
  switch (locationType) {
    case "srs":
      formData.value.populationConfig.srs =
        formData.value.populationConfig.srs || {};
      formData.value.populationConfig.srs = {
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      };
      break;
    case "dem":
      formData.value.populationConfig.dem =
        formData.value.populationConfig.dem || {};
      formData.value.populationConfig.dem = {
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      };
      break;
    case "imageDir":
      formData.value.populationConfig.imageDir =
        formData.value.populationConfig.imageDir || [];
      formData.value.populationConfig.imageDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "eopsDir":
      formData.value.populationConfig.eopsDir =
        formData.value.populationConfig.eopsDir || [];
      formData.value.populationConfig.eopsDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "trajectoryDir":
      formData.value.populationConfig.trajectoryDir =
        formData.value.populationConfig.trajectoryDir || [];
      formData.value.populationConfig.trajectoryDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "lidarDir":
      formData.value.populationConfig.lidarDir =
        formData.value.populationConfig.lidarDir || [];
      formData.value.populationConfig.lidarDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
  }
  refetchSerializedPopulationConfig(formData.value.populationConfig);
};

const onCreateScene = async (scene: Create_SceneMutationVariables) => {
  try {
    const res = await createScene(scene);

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully created",
        life: 3000,
      });
      window.localStorage.removeItem("mlwn-scene-form");
      router.push({
        name: "project-scene-detail",
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while creating",
        life: 3000,
      });
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while creating. " + err?.message,
      life: 3000,
    });
    console.error(err);
  }
};

const onSceneIdChange = () => {
  checkSceneIdExist(formData.value.id);
};

const checkSceneIdExist = (id: string) => {
  formData.value.idExist = false;

  const { exist } = sceneExist(id);
  watch(exist, () => {
    if (exist.value) {
      formData.value.idExist = exist.value === true;
    }
  });
};

watch(
  () => formData.value.populationConfig,
  (value) => {
    refetchSerializedPopulationConfig(value);
  }
);

watch(
  () => formData.value.vadstenaPipeline,
  (value) => {
    formData.value.populationConfig = defaultPopulationConfig[value];
  }
);

const populationConfigSerializedValidated = computed(() => {
  return populationConfigSerialized.value?.replace(
    /(__FILL__)/g,
    '<span class="p-error font-bold">__FILL__</span>'
  );
});
const populationConfigSerializedValid = computed(() => {
  return populationConfigSerialized.value?.match(/(__FILL__)/g) === null;
});

return (_ctx: any,_cache: any) => {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_JsonEditor = _resolveComponent("JsonEditor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _cache[11] || (_cache[11] = _createTextVNode(" Create new scene ")),
          _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_Badge, {
            value: "Expert mode",
            severity: "danger"
          })
        ]),
        content: _withCtx(() => [
          _createVNode(_unref(Form), {
            "initial-values": initialValues.value,
            "validation-schema": _unref(schema),
            class: "p-fluid"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _cache[13] || (_cache[13] = _createElementVNode("label", { for: "id" }, "Scene Id", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: formData.value.id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.id) = $event)),
                      name: "id",
                      label: "Scene id",
                      onChange: onSceneIdChange
                    }, null, 8, ["modelValue"]),
                    (formData.value.idExist)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Scene Id already exist"))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", null, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", { for: "name" }, "Scene Name", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: formData.value.name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.name) = $event)),
                      name: "name",
                      label: "Scene name"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", null, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", { for: "notes" }, "Notes", -1)),
                    _createVNode(_component_InputText, {
                      modelValue: formData.value.notes,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.notes) = $event)),
                      name: "notes",
                      label: "Notes"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", null, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", { for: "vadstenaPipeline" }, "Pipeline Type", -1)),
                    _createVNode(_component_Dropdown, {
                      modelValue: formData.value.vadstenaPipeline,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.vadstenaPipeline) = $event)),
                      options: pipelineTypes.value,
                      optionLabel: "label",
                      optionGroupLabel: "label",
                      optionGroupChildren: "items",
                      optionValue: "value",
                      placeholder: "Select a Pipeline",
                      class: "w-full"
                    }, {
                      optiongroup: _withCtx((slotProps) => [
                        _createElementVNode("div", _hoisted_6, [
                          _cache[16] || (_cache[16] = _createElementVNode("i", { class: "pi pi-folder" }, null, -1)),
                          _cache[17] || (_cache[17] = _createTextVNode("   ")),
                          _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Panel, { header: "Locations settings" }, {
                    default: _withCtx(() => [
                      (!anyShowDataBrowseButton.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, " No actions available. "))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_9, [
                        (showDataBrowseButton.value.srs)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[4] || (_cache[4] = ($event: any) => (onSelectLocation($event, 'srs'))),
                                title: "label",
                                "button-text": "Locate SRS file",
                                leaf: "file"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (showDataBrowseButton.value.dem)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[5] || (_cache[5] = ($event: any) => (onSelectLocation($event, 'dem'))),
                                title: "label",
                                "button-text": "Locate DEM dir",
                                leaf: "dir"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (showDataBrowseButton.value.imageDir)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[6] || (_cache[6] = ($event: any) => (onSelectLocation($event, 'imageDir'))),
                                title: "Add Image dir",
                                "button-text": "Add Image dir",
                                leaf: "dir"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (showDataBrowseButton.value.eopsDir)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[7] || (_cache[7] = ($event: any) => (onSelectLocation($event, 'eopsDir'))),
                                title: "Add Eops dir",
                                "button-text": "Add Eops dir",
                                leaf: "dir"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (showDataBrowseButton.value.trajectoryDir)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[8] || (_cache[8] = ($event: any) => (onSelectLocation($event, 'trajectoryDir'))),
                                title: "Add Trajectory dir",
                                "button-text": "Add Trajectory dir",
                                leaf: "dir"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (showDataBrowseButton.value.lidarDir)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createVNode(DataBrowserSelect, {
                                onSelectFile: _cache[9] || (_cache[9] = ($event: any) => (onSelectLocation($event, 'lidarDir'))),
                                title: "Add LIDAR dir",
                                "button-text": "Add LIDAR dir",
                                leaf: "dir"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _cache[20] || (_cache[20] = _createElementVNode("h4", null, "Ingest Configuration", -1)),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_JsonEditor, {
                    label: "populationConfig",
                    modelValue: formData.value.populationConfig,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formData.value.populationConfig) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_Panel, null, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_19, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex-grow-1 font-bold" }, "Config preview", -1)),
                        _createElementVNode("div", _hoisted_20, [
                          (!populationConfigSerializedValid.value)
                            ? (_openBlock(), _createBlock(_component_Badge, {
                                key: 0,
                                value: "Fill placeholders!",
                                severity: "danger"
                              }))
                            : (_openBlock(), _createBlock(_component_Badge, {
                                key: 1,
                                value: "Looks ok",
                                severity: "success"
                              }))
                        ])
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_21, [
                        (_unref(loadingSerializedPopulationConfig))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_22, "Loading..."))
                          : (_openBlock(), _createElementBlock("pre", {
                              key: 1,
                              innerHTML: populationConfigSerializedValidated.value
                            }, null, 8, _hoisted_23))
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_Button, {
                  disabled: !populationConfigSerializedValid.value,
                  label: 'Create scene',
                  onClick: onComplete,
                  icon: "fa-solid fa-check",
                  class: "p-button-success mr-2 mb-2 mlwn-button-submit float-right"
                }, null, 8, ["disabled"])
              ])
            ]),
            _: 1
          }, 8, ["initial-values", "validation-schema"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})