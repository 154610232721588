<template>
  <div class="field" :class="fieldClass">
    <InfoInputGroup :hint-content="hintContent" :hint-title="hintTitle" :float-label-class="!minimize">
      <Dropdown 
      scroll-height="400px"
      class="w-full"
      :aria-describedby="`${name}-help`"
        :label="label"
        v-model="value"
        :options="options"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :placeholder="placeholder"
        :loading="loading"
        :disabled="disabled"
        :showClear="showClear"
        :filter="filter"
        :optionGroupLabel="optionGroupLabel" 
        :optionGroupChildren="optionGroupChildren"
      >
        <slot name="dropdown-content"></slot>
      </Dropdown>
      <label :for="name" v-if="!minimize">{{ label }}</label>
    </InfoInputGroup>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script lang="ts" setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import Dropdown from 'primevue/dropdown';
import InfoInputGroup from '../InfoInputGroup.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  optionLabel: {
    required: false,
    default: 'label'
  },
  optionValue: {
    required: false,
    default: 'value'
  },
  optionGroupLabel: {
    type: String,
    required: false,
  },
  optionGroupChildren: {
    type: String,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Please select an option'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showClear: {
    type: Boolean,
    default: true
  },
  filter: {
    type: Boolean,
    default: true
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { errorMessage, value } = useField(toRef(props, 'name'));
</script>
