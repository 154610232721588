<template>
  <div class="grid">
    <div class="col float">
      <h2 class="text-2xl font-bold">Targets</h2>
    </div>
  </div>
  <div class="grid">
    <div class="col-6">
      <SceneAddTargetsDialog
        v-if="scene && defaultTargetLayoutId"
        :scene-id="scene.id"
        :target-layout-id="defaultTargetLayoutId"
        @targets-added="onTargetsUpdated"
      />
    </div>
    <div class="col-6 text-right">
      Selected ({{ selectedTargetsIds && selectedTargetsIds.length }}) &nbsp;
      <a
        size="small"
        severity="primary"
        icon="fa-solid fa-search"
        label="Select all "
        href="#"
        :disabled="targets.length === 0"
        @click="onSelectAllTargets"
        >All table</a
      >
      &nbsp;
      <TargetUpdateDialog
        :targets-ids="selectedTargetsIds"
        @targets-updated="onTargetsUpdated"
      />
    </div>
  </div>

  <template v-if="sceneLoading">
    <p>Loading scene...</p>
  </template>
  <template v-else>
    <template v-if="!defaultTargetLayoutId">
      <p>No default target layout found.</p>
    </template>
    <template v-else>
      <DataTable
        v-model:selection="selectedTargets"
        dataKey="id"
        :value="targets"
        :loading="targetsLoading"
        :loadingText="'Loading targets...'"
        :emptyText="'No targets yet.'"
        :size="'small'"
        paginator
        lazy
        :filters="filters"
        filterDisplay="row"
        :globalFilterFields="['filePersistenceLevel', 'statusAlias']"
        :rows="pageInfoTargets.limit"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        :totalRecords="pageInfoTargets.total"
        @page="onPage"
        @sort="onSort"
        @filter="onFilter"
      >
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="id" key="id" header="ID" sortable>
          <template #body="{ data }">
            {{ data.id }}
          </template>
        </Column>
        <Column field="extents" key="extents" header="Extents" sortable>
          <template #body="{ data }">
            {{ data.extents }}
          </template>
        </Column>
        <Column field="priority" key="priority" header="Priority" sortable 
          :showFilterMenu="false">
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="targetFilePersistenceLevels"
              optionLabel="priority"
              optionValue="priority"
              placeholder=""
              class="p-column-filter"
              style="min-width: 12rem"
            >
            </InputText>
          </template>
          <template #body="{ data }">
            {{ data.priority }}
          </template>
        </Column>
        <Column
          filterField="filePersistenceLevel"
          field="filePersistenceLevel"
          key="filePersistenceLevel"
          header="File Persistance Level"
          sortable
          :showFilterMenu="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="targetFilePersistenceLevels"
              optionLabel="value"
              optionValue="value"
              placeholder="Select"
              class="p-column-filter"
              style="min-width: 12rem"
            >
              <template #option="slotProps">
                {{ slotProps.option.vadstenaValue }}
              </template>
            </Dropdown>
          </template>
          <template #body="{ data }">
            {{ data.filePersistenceLevel }}
          </template>
        </Column>
        <Column field="products" key="products" header="Products">
          <template #body="{ data }">
            {{ data.products.length }} products
          </template>
        </Column>
        <Column key="requests" header="Requests" sortable>
          <template #body="{ data }">
            <template v-if="data.requestToPurge">
              <Badge severity="warning" value="Purge" />
            </template>
            <template v-if="data.requestToForcePurge">
              <Badge severity="danger" value="Force Purge" />
            </template>
          </template>
        </Column>
        <Column
          filterField="statusAlias"
          field="statusAlias"
          key="statusAlias"
          header="Status"
          sortable
          :showFilterMenu="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="targetStatuses"
              optionLabel="alias"
              optionValue="alias"
              placeholder="Select"
              class="p-column-filter"
              style="min-width: 12rem"
            >
              <template #option="slotProps">
                {{ slotProps.option.alias }}
              </template>
            </Dropdown>
          </template>
          <template #body="{ data }">
            {{ data.statusAlias }}
          </template>
        </Column>
      </DataTable>
    </template>
  </template>
</template>

<script lang="ts" setup>
import { useTargets } from "@/composables/useTargets";
import SceneAddTargetsDialog from "../Dialogs/SceneAddTargetsDialog.vue";
import { Ref, ref, watch } from "vue";
import { useScenes } from "@/composables/useScenes";
import TargetUpdateDialog from "../Dialogs/TargetUpdateDialog.vue";
import { InputFilterField, InputFilterFieldTypeEnum, QueryGetTargetsArgs, TargetLayoutNameKeywords } from "@/gql/graphql";

// props
const props = defineProps<{
  sceneId: string;
}>();

// composables
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";
const { getScene } = useScenes();

// composables usage
const {
  getTargets,
  getTargetsIds,
  getTargetFilePersistenceLevels,
  getTargetStatuses,
} = useTargets();
const { targetStatuses } = getTargetStatuses();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();
const router = useRouter();

// refs
const routerQuery = router.currentRoute.value.query;
const selectedTargets = ref();
const selectedTargetsIds: Ref<Array<String>> = ref([]);
const defaultTargetLayoutId = ref();


const targetsQueryIds: Ref<QueryGetTargetsArgs> = ref({});
const targetsQueryIdsCalled = ref(false);

const filters: Ref<any, any> = ref({
  targetLayoutId: { value: routerQuery['targetLayoutId'] || "", matchMode: FilterMatchMode.EQUALS },
  priority: { value: routerQuery['priority'] || null, matchMode: FilterMatchMode.EQUALS },
  filePersistenceLevel: { value: routerQuery['filePersistenceLevel'] || null, matchMode: FilterMatchMode.EQUALS },
  statusAlias: { value: routerQuery['statusAlias'] || null, matchMode: FilterMatchMode.EQUALS },
});
const targetsQuery: Ref<QueryGetTargetsArgs> = ref({
  limit:
    routerQuery.limit && typeof routerQuery.limit === "string"
      ? parseInt(routerQuery.limit, 10)
      : 5,
  offset:
    routerQuery.offset && typeof routerQuery.offset === "string"
      ? parseInt(routerQuery.offset, 10)
      : 5,
  ...(routerQuery.sortField
    ? {
      sort: {
        field: routerQuery.sortField.toString(),
        order: routerQuery.sortOrder === "asc" ? "asc" : "desc",
      },
    }
    : {}),
  filter: {
    fields: [],
  },
});
Object.keys(filters.value).forEach((filterKey) => {
  if (!routerQuery[filterKey]) {
    return
  }
  targetsQuery.value.filter?.fields?.push({
    name: filterKey,
    type: InputFilterFieldTypeEnum.Equals,
    value: filters.value[filterKey].value,
  });
});

// composables usage
const { scene, loading: sceneLoading } = getScene(props.sceneId);
const {
  targets,
  load: loadTargets,
  pageInfo: pageInfoTargets,
  loading: targetsLoading,
  refetch: refetchTargets,
} = getTargets(targetsQuery, 3000);
const {
  targets: targetsIds,
  load: loadTargetsIds,
  refetch: refetchTargetsIds,
} = getTargetsIds(targetsQueryIds);

// watch
watch(targetsQuery, () => {
  serializeQuery();
});

watch(scene, () => {
  const defaultTargetLayout = scene.value.targetLayouts.find(
    (layout) => layout.name === TargetLayoutNameKeywords.Default
  );
  if (defaultTargetLayout) {
    defaultTargetLayoutId.value = defaultTargetLayout.id;
    filters.value.targetLayoutId.value = defaultTargetLayout.id.toString();
    const filterQuery = {
      filter: {
        fields: Object.keys(filters.value)
          .filter((filterKey) => filters.value[filterKey].value !== null)
          .map((filterKey) => {
            return {
              name: filterKey,
              type: InputFilterFieldTypeEnum.Equals,
              value: filters.value[filterKey].value,
            }
          }),
      },
    };
    targetsQuery.value = {
      ...targetsQuery.value,
      ...filterQuery,
    };
    targetsQueryIds.value = {
      ...filterQuery,
    };
    loadTargets();
  }
});

watch(selectedTargets, () => {
  selectedTargetsIds.value = selectedTargets.value
    ? selectedTargets.value.map((target: any) => target.id)
    : [];
});
watch(targetsIds, () => {
  selectedTargetsIds.value = targetsIds.value
    ? targetsIds.value.map((target: any) => target.id)
    : [];
});

// methods
const onTargetsUpdated = () => {
  refetchTargets();
};

const onSelectAllTargets = () => {
  selectedTargets.value = [];
  // load or refetch targetsIds
  if (targetsQueryIdsCalled.value) {
    refetchTargetsIds();
  } else {
    loadTargetsIds();
    targetsQueryIdsCalled.value = true;
  }
};

const onPage = (event: any) => {
  targetsQuery.value = {
    ...targetsQuery.value,
    offset: event.first,
    limit: event.rows,
  };
  refetchTargets();
};

const onSort = (event: any) => {
  targetsQuery.value = {
    ...targetsQuery.value,
    sort: {
      field: event.sortField,
      order: event.sortOrder === 1 ? "asc" : "desc",
    },
  };
  refetchTargets();
};

const onFilter = (event: any) => {
  filters.value = {
    ...filters.value,
    ...event.filters,
  };
  targetsQuery.value = {
    ...targetsQuery.value,
    filter: {
      fields: Object.keys(filters.value)
        .filter((filterKey) => filters.value[filterKey].value !== null)
        .map((filterKey: any) => ({
          name: filterKey,
          value: filters.value[filterKey].value,
          type: InputFilterFieldTypeEnum.Equals,
        })),
    },
  };
  refetchTargets();
};

const serializeQuery = () => {
  let fetchQuery: any = {
    limit: targetsQuery.value.limit,
    offset: targetsQuery.value.offset,
    sortField: targetsQuery.value.sort?.field,
    sortOrder: targetsQuery.value.sort?.order,
  };
  Object.keys(filters.value).forEach((filterKey) => {
    fetchQuery = {
      ...fetchQuery,
      [filterKey]: filters.value[filterKey].value,
    };
  });

  router.replace({ query: fetchQuery });
};
</script>
