import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6 text-right" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }

import { useTargets } from "@/composables/useTargets";
import SceneAddTargetsDialog from "../Dialogs/SceneAddTargetsDialog.vue";
import { Ref, ref, watch } from "vue";
import { useScenes } from "@/composables/useScenes";
import TargetUpdateDialog from "../Dialogs/TargetUpdateDialog.vue";
import { InputFilterField, InputFilterFieldTypeEnum, QueryGetTargetsArgs, TargetLayoutNameKeywords } from "@/gql/graphql";

// props
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneTargets',
  props: {
    sceneId: {}
  },
  setup(__props: any) {

const props = __props;

// composables
const { getScene } = useScenes();

// composables usage
const {
  getTargets,
  getTargetsIds,
  getTargetFilePersistenceLevels,
  getTargetStatuses,
} = useTargets();
const { targetStatuses } = getTargetStatuses();
const { targetFilePersistenceLevels } = getTargetFilePersistenceLevels();
const router = useRouter();

// refs
const routerQuery = router.currentRoute.value.query;
const selectedTargets = ref();
const selectedTargetsIds: Ref<Array<String>> = ref([]);
const defaultTargetLayoutId = ref();


const targetsQueryIds: Ref<QueryGetTargetsArgs> = ref({});
const targetsQueryIdsCalled = ref(false);

const filters: Ref<any, any> = ref({
  targetLayoutId: { value: routerQuery['targetLayoutId'] || "", matchMode: FilterMatchMode.EQUALS },
  priority: { value: routerQuery['priority'] || null, matchMode: FilterMatchMode.EQUALS },
  filePersistenceLevel: { value: routerQuery['filePersistenceLevel'] || null, matchMode: FilterMatchMode.EQUALS },
  statusAlias: { value: routerQuery['statusAlias'] || null, matchMode: FilterMatchMode.EQUALS },
});
const targetsQuery: Ref<QueryGetTargetsArgs> = ref({
  limit:
    routerQuery.limit && typeof routerQuery.limit === "string"
      ? parseInt(routerQuery.limit, 10)
      : 5,
  offset:
    routerQuery.offset && typeof routerQuery.offset === "string"
      ? parseInt(routerQuery.offset, 10)
      : 5,
  ...(routerQuery.sortField
    ? {
      sort: {
        field: routerQuery.sortField.toString(),
        order: routerQuery.sortOrder === "asc" ? "asc" : "desc",
      },
    }
    : {}),
  filter: {
    fields: [],
  },
});
Object.keys(filters.value).forEach((filterKey) => {
  if (!routerQuery[filterKey]) {
    return
  }
  targetsQuery.value.filter?.fields?.push({
    name: filterKey,
    type: InputFilterFieldTypeEnum.Equals,
    value: filters.value[filterKey].value,
  });
});

// composables usage
const { scene, loading: sceneLoading } = getScene(props.sceneId);
const {
  targets,
  load: loadTargets,
  pageInfo: pageInfoTargets,
  loading: targetsLoading,
  refetch: refetchTargets,
} = getTargets(targetsQuery, 3000);
const {
  targets: targetsIds,
  load: loadTargetsIds,
  refetch: refetchTargetsIds,
} = getTargetsIds(targetsQueryIds);

// watch
watch(targetsQuery, () => {
  serializeQuery();
});

watch(scene, () => {
  const defaultTargetLayout = scene.value.targetLayouts.find(
    (layout) => layout.name === TargetLayoutNameKeywords.Default
  );
  if (defaultTargetLayout) {
    defaultTargetLayoutId.value = defaultTargetLayout.id;
    filters.value.targetLayoutId.value = defaultTargetLayout.id.toString();
    const filterQuery = {
      filter: {
        fields: Object.keys(filters.value)
          .filter((filterKey) => filters.value[filterKey].value !== null)
          .map((filterKey) => {
            return {
              name: filterKey,
              type: InputFilterFieldTypeEnum.Equals,
              value: filters.value[filterKey].value,
            }
          }),
      },
    };
    targetsQuery.value = {
      ...targetsQuery.value,
      ...filterQuery,
    };
    targetsQueryIds.value = {
      ...filterQuery,
    };
    loadTargets();
  }
});

watch(selectedTargets, () => {
  selectedTargetsIds.value = selectedTargets.value
    ? selectedTargets.value.map((target: any) => target.id)
    : [];
});
watch(targetsIds, () => {
  selectedTargetsIds.value = targetsIds.value
    ? targetsIds.value.map((target: any) => target.id)
    : [];
});

// methods
const onTargetsUpdated = () => {
  refetchTargets();
};

const onSelectAllTargets = () => {
  selectedTargets.value = [];
  // load or refetch targetsIds
  if (targetsQueryIdsCalled.value) {
    refetchTargetsIds();
  } else {
    loadTargetsIds();
    targetsQueryIdsCalled.value = true;
  }
};

const onPage = (event: any) => {
  targetsQuery.value = {
    ...targetsQuery.value,
    offset: event.first,
    limit: event.rows,
  };
  refetchTargets();
};

const onSort = (event: any) => {
  targetsQuery.value = {
    ...targetsQuery.value,
    sort: {
      field: event.sortField,
      order: event.sortOrder === 1 ? "asc" : "desc",
    },
  };
  refetchTargets();
};

const onFilter = (event: any) => {
  filters.value = {
    ...filters.value,
    ...event.filters,
  };
  targetsQuery.value = {
    ...targetsQuery.value,
    filter: {
      fields: Object.keys(filters.value)
        .filter((filterKey) => filters.value[filterKey].value !== null)
        .map((filterKey: any) => ({
          name: filterKey,
          value: filters.value[filterKey].value,
          type: InputFilterFieldTypeEnum.Equals,
        })),
    },
  };
  refetchTargets();
};

const serializeQuery = () => {
  let fetchQuery: any = {
    limit: targetsQuery.value.limit,
    offset: targetsQuery.value.offset,
    sortField: targetsQuery.value.sort?.field,
    sortOrder: targetsQuery.value.sort?.order,
  };
  Object.keys(filters.value).forEach((filterKey) => {
    fetchQuery = {
      ...fetchQuery,
      [filterKey]: filters.value[filterKey].value,
    };
  });

  router.replace({ query: fetchQuery });
};

return (_ctx: any,_cache: any) => {
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "grid" }, [
      _createElementVNode("div", { class: "col float" }, [
        _createElementVNode("h2", { class: "text-2xl font-bold" }, "Targets")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_unref(scene) && defaultTargetLayoutId.value)
          ? (_openBlock(), _createBlock(SceneAddTargetsDialog, {
              key: 0,
              "scene-id": _unref(scene).id,
              "target-layout-id": defaultTargetLayoutId.value,
              onTargetsAdded: onTargetsUpdated
            }, null, 8, ["scene-id", "target-layout-id"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(" Selected (" + _toDisplayString(selectedTargetsIds.value && selectedTargetsIds.value.length) + ")   ", 1),
        _createElementVNode("a", {
          size: "small",
          severity: "primary",
          icon: "fa-solid fa-search",
          label: "Select all ",
          href: "#",
          disabled: _unref(targets).length === 0,
          onClick: onSelectAllTargets
        }, "All table", 8, _hoisted_4),
        _cache[1] || (_cache[1] = _createTextVNode("   ")),
        _createVNode(TargetUpdateDialog, {
          "targets-ids": selectedTargetsIds.value,
          onTargetsUpdated: onTargetsUpdated
        }, null, 8, ["targets-ids"])
      ])
    ]),
    (_unref(sceneLoading))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Loading scene..."))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!defaultTargetLayoutId.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, "No default target layout found."))
            : (_openBlock(), _createBlock(_component_DataTable, {
                key: 1,
                selection: selectedTargets.value,
                "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((selectedTargets).value = $event)),
                dataKey: "id",
                value: _unref(targets),
                loading: _unref(targetsLoading),
                loadingText: 'Loading targets...',
                emptyText: 'No targets yet.',
                size: 'small',
                paginator: "",
                lazy: "",
                filters: filters.value,
                filterDisplay: "row",
                globalFilterFields: ['filePersistenceLevel', 'statusAlias'],
                rows: _unref(pageInfoTargets).limit,
                rowsPerPageOptions: [5, 10, 20, 50],
                totalRecords: _unref(pageInfoTargets).total,
                onPage: onPage,
                onSort: onSort,
                onFilter: onFilter
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    selectionMode: "multiple",
                    headerStyle: "width: 3rem"
                  }),
                  _createVNode(_component_Column, {
                    field: "id",
                    key: "id",
                    header: "ID",
                    sortable: ""
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.id), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "extents",
                    key: "extents",
                    header: "Extents",
                    sortable: ""
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.extents), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "priority",
                    key: "priority",
                    header: "Priority",
                    sortable: "",
                    showFilterMenu: false
                  }, {
                    filter: _withCtx(({ filterModel, filterCallback }) => [
                      _createVNode(_component_InputText, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        onChange: ($event: any) => (filterCallback()),
                        options: _unref(targetFilePersistenceLevels),
                        optionLabel: "priority",
                        optionValue: "priority",
                        placeholder: "",
                        class: "p-column-filter",
                        style: {"min-width":"12rem"}
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
                    ]),
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.priority), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    filterField: "filePersistenceLevel",
                    field: "filePersistenceLevel",
                    key: "filePersistenceLevel",
                    header: "File Persistance Level",
                    sortable: "",
                    showFilterMenu: false
                  }, {
                    filter: _withCtx(({ filterModel, filterCallback }) => [
                      _createVNode(_component_Dropdown, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        onChange: ($event: any) => (filterCallback()),
                        options: _unref(targetFilePersistenceLevels),
                        optionLabel: "value",
                        optionValue: "value",
                        placeholder: "Select",
                        class: "p-column-filter",
                        style: {"min-width":"12rem"}
                      }, {
                        option: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.option.vadstenaValue), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
                    ]),
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.filePersistenceLevel), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "products",
                    key: "products",
                    header: "Products"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.products.length) + " products ", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    key: "requests",
                    header: "Requests",
                    sortable: ""
                  }, {
                    body: _withCtx(({ data }) => [
                      (data.requestToPurge)
                        ? (_openBlock(), _createBlock(_component_Badge, {
                            key: 0,
                            severity: "warning",
                            value: "Purge"
                          }))
                        : _createCommentVNode("", true),
                      (data.requestToForcePurge)
                        ? (_openBlock(), _createBlock(_component_Badge, {
                            key: 1,
                            severity: "danger",
                            value: "Force Purge"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    filterField: "statusAlias",
                    field: "statusAlias",
                    key: "statusAlias",
                    header: "Status",
                    sortable: "",
                    showFilterMenu: false
                  }, {
                    filter: _withCtx(({ filterModel, filterCallback }) => [
                      _createVNode(_component_Dropdown, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                        onChange: ($event: any) => (filterCallback()),
                        options: _unref(targetStatuses),
                        optionLabel: "alias",
                        optionValue: "alias",
                        placeholder: "Select",
                        class: "p-column-filter",
                        style: {"min-width":"12rem"}
                      }, {
                        option: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.option.alias), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
                    ]),
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(data.statusAlias), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["selection", "value", "loading", "filters", "rows", "totalRecords"]))
        ], 64))
  ], 64))
}
}

})