import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import SceneProcessingForm from "./components/SceneProcessingForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SceneProcessingConfiguration',
  props: {
  sceneId: {
    required: true,
    type: String,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, [
        _createVNode(_component_Panel, null, {
          header: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("span", null, "Processing configuration", -1)
          ])),
          default: _withCtx(() => [
            _createVNode(SceneProcessingForm, {
              sceneId: props.sceneId
            }, null, 8, ["sceneId"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})