import { gql } from "@/gql";

export const CREATE_TARGETS = gql(`
mutation CreateTargets($targets: [TargetCreateInput!]!) {
  createTargets(targets: $targets) {
    id
  }
}
`)

export const UPDATE_TARGETS = gql(`
  mutation UpdateTargets($targets: [TargetUpdateInput!]!) {
    updateTargets(targets: $targets) {
      id
    }
  }
`)
