import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { "data-cy": 'clusterList__clusterId' }
const _hoisted_6 = { "data-cy": 'clusterList__clusterCollocation' }
const _hoisted_7 = { "data-cy": 'clusterList__provisioningDmTaskId' }
const _hoisted_8 = { "data-cy": 'clusterList__serviceDmTaskId' }
const _hoisted_9 = { "data-cy": 'clusterList__cleanupDmTaskId' }
const _hoisted_10 = { "data-cy": 'clusterList__clusterStatus' }
const _hoisted_11 = { class: "action-buttons" }

import { watch } from 'vue';
import { useTables } from '@/composables/useTables';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { useClusters } from '@/composables/useClusters'
import ErrorDialog from '@/components/UI/ErrorDialog.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ClusterList',
  setup(__props) {

const { getClusters, stopClusterService, updateCluster } = useClusters()
const {
  data: clusters,
  loading: loadingClusters,
  error: errorClusters,
} = getClusters()

const toast = useToast()

const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({

  filters: {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "global"
    },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    dmCollocationId: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "dmCollocationId"
    }
  }
})

// watches

watch(errorClusters, () => {
  if (errorClusters.value) {
    toast.add({ severity: 'error', summary: 'Error loading clusters', detail: 'Try to log in again or contact administrator.' })
  }
})

/*
const onRestartClick = async (id: number) => {
  const { success } = await restartCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}
*/

const onStopClick = async (id: string) => {
  const { success } = await stopClusterService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}

const onCleanupClick = async (id: string) => {
  const { success } = await updateCluster({
    id,
    requestToCleanup: true
  })
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Cleanup succesfully requested', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot trigger cleanup.', life: 3000 })
  }
}


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, "Clusters", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, { to: {
                name: 'cluster-create'
              } }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    "data-cy": "cluserList__createCluster",
                    type: "button",
                    icon: "fa-solid fa-plus",
                    label: "Create cluster"
                  })
                ]),
                _: 1
              })), [
                [
                  _directive_tooltip,
                  'Create new cluster',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            value: _unref(clusters),
            onPage: _cache[0] || (_cache[0] = ($event: any) => (_unref(onPage)($event))),
            onSort: _cache[1] || (_cache[1] = ($event: any) => (_unref(onSort)($event))),
            onFilter: _cache[2] || (_cache[2] = ($event: any) => (_unref(onFilter)($event))),
            sortField: _unref(defaultSortField),
            sortOrder: _unref(defaultSortOrder),
            lazy: _unref(lazy),
            class: "p-datatable-sm",
            rows: _unref(rows),
            dataKey: "id",
            rowHover: true,
            filters: _unref(tableFilters),
            "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
            filterDisplay: "menu",
            loading: _unref(loadingClusters),
            paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            rowsPerPageOptions: _unref(rowsPerPageOptions),
            currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
            responsiveLayout: "scroll"
          }, {
            empty: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" No cluster found. ")
            ])),
            loading: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin" }, null, -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "id",
                header: "Id",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(data.id), 1),
                  (data.error && data.error !== 'null')
                    ? (_openBlock(), _createBlock(ErrorDialog, {
                        key: 0,
                        error: data.error
                      }, null, 8, ["error"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "dmCollocationId",
                header: "Collocation",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(data.dmCollocationId), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "provisioningDmTaskId",
                header: "Provisioning task id",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(data.provisioningDmTaskId || 'NULL'), 1),
                  _createTextVNode(" " + _toDisplayString(data.requestToCleanup), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "serviceDmTaskId",
                header: "Service task id",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(data.serviceDmTaskId || 'NULL'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "cleanupDmTaskId",
                header: "Cleanup task id",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(data.cleanupDmTaskId || 'NULL'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "status.alias",
                header: "Status",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(data.status.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                headerStyle: "width: 8rem; text-align: center",
                bodyStyle: "text-align: right; overflow: visible"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_11, [
                    (data.status.alias === 'Running')
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          "data-cy": "clusterList__clusterStopButton",
                          type: "button",
                          severity: "danger",
                          icon: "pi pi-power-off",
                          size: "small",
                          onClick: ($event: any) => (onStopClick(data.id)),
                          label: "Stop"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Stop cluster',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true),
                    (data.status.alias === 'ProvisioningFailed' || data.status.alias === 'ServiceFailed')
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          "data-cy": "clusterList__clusterCleanupFailedCluster",
                          type: "button",
                          severity: "warning",
                          icon: "pi pi-power-off",
                          size: "small",
                          onClick: ($event: any) => (onCleanupClick(data.id)),
                          label: "Cleanup cluster"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Trigger cleanup of failed cluster',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "sortField", "sortOrder", "lazy", "rows", "filters", "loading", "rowsPerPageOptions"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})