import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import HintDialog from '../HintDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoInputGroup',
  props: {
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  floatLabelClass: {
    type: Boolean,
    required: false,
    default: true
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([__props.floatLabelClass ? 'p-float-label': '', "p-input-icon-right mlwn-hint-input-group w-full"])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (__props.hintContent)
      ? (_openBlock(), _createBlock(HintDialog, {
          key: 0,
          title: __props.hintTitle,
          hint: __props.hintContent
        }, null, 8, ["title", "hint"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})