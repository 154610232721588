<template>
  <div class="grid">
    <div class="col-12">
      <SceneTargets :sceneId="props.sceneId" />

      <!-- <h1>Products</h1> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import SceneTargets from './SceneTargets.vue';

// props
const props = defineProps<{
  sceneId: string
}>()

</script>