export enum VadstenaPipelineType {
  Uav = 'uav',
  Blk = 'blk',
  Supermesh = 'supermesh'
}

export enum SceneAttributes {
  gsd = 'gsd',
  outputPointDensity = 'outputPointDensity',
  outputResolution = 'outputResolution',
  meshEdgeLength = 'meshEdgeLength',
  texelSize = 'texelSize',
}

export const groupedPipelinePresets = [
  {
    label: 'UAV',
    code: 'UAV',
    items: [
      { label: 'UAV Generic', value: VadstenaPipelineType.Uav }
    ]
  },
  {
    label: 'BLK',
    code: 'BLK',
    items: [
      { label: 'BLK Generic', value: VadstenaPipelineType.Blk },
    ]
  },
  {
    label: 'Supermesh',
    code: 'Supermesh',
    items: [
      { label: 'Supermesh Generic', value: VadstenaPipelineType.Supermesh },
    ]
  }
]

type TPopulationConfig = {
  [key: string]: any
}

export type TSceneOption = {
  option: string,
  utilityId: string,
  value: any
}

export const defaultPopulationConfig: Record<VadstenaPipelineType, TPopulationConfig> = {
  [VadstenaPipelineType.Uav]: {
    "srs": {
      "path": "__FILL__",
      "volumeMountAlias": "__FILL__"
    },
    "dem": {
      "path": "__FILL__",
      "volumeMountAlias": "__FILL__",
    },
    "demResolution": 10,
    "imageDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      }
    ],
    "trajectoryDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      }
    ]
  },
  [VadstenaPipelineType.Blk]: {
    "srs": {
      "volumeMountAlias": "__FILL__",
      "path": "__FILL__",
    },
    "dem": {
      "path": "__FILL__",
      "volumeMountAlias": "__FILL__"
    },
    "demResolution": 10,
    imageDir: [
      {
        "volumeMountAlias": "__FILL__",
        "path": "__FILL__",
      }
    ],
    CameraDef: [
      {
        name: 'cam1',
        options: [
          {
            key: 'type',
            value: 'tukan',
          },
          {
            key: 'kind',
            value: 'ground-level',
          },
        ],
      },
      {
        name: 'cam2',
        options: [
          {
            key: 'type',
            value: 'tukan',
          },
          {
            key: 'kind',
            value: 'ground-level',
          },
        ],
      },
      {
        name: 'cam3',
        options: [
          {
            key: 'type',
            value: 'tukan',
          },
          {
            key: 'kind',
            value: 'ground-level',
          },
        ],
      },
    ],
    CameraRules: [
      {
        name: 'cam1',
        rule: 'image/*.1.jpg',
      },
      {
        name: 'cam2',
        rule: 'image/*.2.jpg',
      },
      {
        name: 'cam3',
        rule: 'image/*.3.jpg',
      },
    ],
    lidarDir: [
      {
        "volumeMountAlias": "__FILL__",
        "path": "__FILL__",
      }
    ],
    LidarDef: [
      {
        name: 'lidardata',
        options: [
          {
            key: 'kind',
            value: 'ground-level',
          },
          {
            key: 'sourceId',
            value: '0',
          },
        ],
      },
    ],
    LidarRules: [
      {
        name: 'lidardata',
        rule: '__FILL__',
      },
    ],
    trajectoryDir: [
      {
        "volumeMountAlias": "__FILL__",
        "path": "__FILL__",
      }
    ],
    TrajectoryDef: [
      {
        name: 'trajectories',
        options: [
          {
            key: 'type',
            value: 'tukan',
          },
          {
            key: 'sourceId',
            value: '0',
          },
        ],
      },
    ],
    TrajectoryRules: [
      {
        name: 'trajectories',
        rule: '__FILL__',
      },
    ],
  },
  [VadstenaPipelineType.Supermesh]: {
    "dem": {
      "path": "__FILL__",
      "volumeMountAlias": "__FILL__"
    },
    "demResolution": 10,
    "srs": {
      "path": "__FILL__",
      "volumeMountAlias": "__FILL__"
    },
    "eopsDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      }
    ],
    "eopsAngleUnit": "deg",
    "LidarDef": [
      {
        "name": "als",
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          },
          {
            "key": "sourceId",
            "value": "0"
          },
          {
            "key": "srsOverride",
            "value": "_EMPTY_SRS"
          }
        ]
      },
      {
        "name": "mls1",
        "options": [
          {
            "key": "kind",
            "value": "ground-level"
          },
          {
            "key": "sourceId",
            "value": "1"
          },
          {
            "key": "srsOverride",
            "value": "_EMPTY_SRS"
          }
        ]
      },
      {
        "name": "mls2",
        "options": [
          {
            "key": "kind",
            "value": "ground-level"
          },
          {
            "key": "sourceId",
            "value": "2"
          },
          {
            "key": "srsOverride",
            "value": "_EMPTY_SRS"
          }
        ]
      }
    ],
    "imageDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      },
    ],
    "lidarDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      },
    ],
    "CameraDef": [
      {
        "name": "ground",
        "up_x_m": 0,
        "up_y_m": 0,
        "up_z_m": 1,
        "options": [
          {
            "key": "kind",
            "value": "ground-level"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 1,
        "look_z_m": 0,
        "px_width_um": 10,
        "px_height_um": 10,
        "focalLenght_mm": 7.45371,
        "image_width_px": 2129,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 4096
      },
      {
        "name": "nadir",
        "up_x_m": 0,
        "up_y_m": 1,
        "up_z_m": 0,
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          },
          {
            "key": "nadir",
            "value": "1"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 0,
        "look_z_m": -1,
        "px_width_um": 3.76,
        "px_height_um": 3.76,
        "focalLenght_mm": 112,
        "image_width_px": 14192,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 10640
      },
      {
        "name": "left",
        "up_x_m": 0,
        "up_y_m": 1,
        "up_z_m": 0,
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 0,
        "look_z_m": -1,
        "px_width_um": 3.76,
        "px_height_um": 3.76,
        "focalLenght_mm": 146,
        "image_width_px": 14192,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 10640
      },
      {
        "name": "right",
        "up_x_m": 0,
        "up_y_m": 1,
        "up_z_m": 0,
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 0,
        "look_z_m": -1,
        "px_width_um": 3.76,
        "px_height_um": 3.76,
        "focalLenght_mm": 146,
        "image_width_px": 14192,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 10640
      },
      {
        "name": "forward",
        "up_x_m": 0,
        "up_y_m": 1,
        "up_z_m": 0,
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 0,
        "look_z_m": -1,
        "px_width_um": 3.76,
        "px_height_um": 3.76,
        "focalLenght_mm": 146,
        "image_width_px": 14192,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 10640
      },
      {
        "name": "backward",
        "up_x_m": 0,
        "up_y_m": 1,
        "up_z_m": 0,
        "options": [
          {
            "key": "kind",
            "value": "aerial"
          }
        ],
        "look_x_m": 0,
        "look_y_m": 0,
        "look_z_m": -1,
        "px_width_um": 3.76,
        "px_height_um": 3.76,
        "focalLenght_mm": 146,
        "image_width_px": 14192,
        "lensShift_x_mm": 0,
        "lensShift_y_mm": 0,
        "image_height_px": 10640
      }
    ],
    "LidarRules": [
      {
        "name": "als",
        "rule": "__FILL__"
      },
      {
        "name": "mls1",
        "rule": "__FILL__"
      },
      {
        "name": "mls2",
        "rule": "__FILL__"
      }
    ],
    "Trajectory": true,
    "trajectory": true,
    "CameraRules": [
      {
        "name": "ground",
        "rule": "__FILL__/*.jpg"
      },
      {
        "name": "nadir",
        "rule": "__FILL__/*Nadir.tif"
      },
      {
        "name": "left",
        "rule": "__FILL__/*Left.jpg"
      },
      {
        "name": "right",
        "rule": "__FILL__/*Right.jpg"
      },
      {
        "name": "forward",
        "rule": "__FILL__/*Forward.jpg"
      },
      {
        "name": "backward",
        "rule": "__FILL__/*Backward.jpg"
      }
    ],
    "TrajectoryDef": [
      {
        "name": "als",
        "options": [
          {
            "key": "sourceId",
            "value": "0"
          }
        ]
      },
      {
        "name": "mls1",
        "options": [
          {
            "key": "sourceId",
            "value": "1"
          }
        ]
      },
      {
        "name": "mls2",
        "options": [
          {
            "key": "sourceId",
            "value": "2"
          }
        ]
      }
    ],
    "trajectoryDir": [
      {
        "path": "__FILL__",
        "volumeMountAlias": "__FILL__"
      }
    ],
    "TrajectoryRules": [
      {
        "name": "als",
        "rule": "__FILL__/*"
      },
      {
        "name": "mls1",
        "rule": "__FILL__/__FILL__.csv"
      },
      {
        "name": "mls2",
        "rule": "__FILL__/__FILL__.csv"
      }
    ],
  },
}


export const defaultSceneOptions: Record<VadstenaPipelineType, Array<TSceneOption>> = {
  [VadstenaPipelineType.Uav]: [],
  [VadstenaPipelineType.Blk]: [
    {
      "option": "--cellSize",
      "utilityId": "target-lidar",
      "value": "0.2"
    },
    {
      "option": "--pointLimitGroundLevel",
      "utilityId": "window-window",
      "value": "0"
    },
    {
      "option": "--extendedViewport",
      "utilityId": "window-window",
      "value": "0.2"
    },
    {
      "option": "--lidarReduceBanding",
      "utilityId": "window-fusion",
      "value": "1"
    },
    {
      "option": "--lidarCarveSpaceStrength",
      "utilityId": "window-fusion",
      "value": "3"
    },
    {
      "option": "--lidarInflateSurface",
      "utilityId": "window-fusion",
      "value": "0.33"
    },
    {
      "option": "--lidarNormalsMinPoints",
      "utilityId": "window-fusion",
      "value": "30"
    },
    {
      "option": "--lidarNormalsRadiusCells",
      "utilityId": "window-fusion",
      "value": "2"
    },
    {
      "option": "--lidarNormalsSmoothing",
      "utilityId": "window-fusion",
      "value": "3"
    },
    {
      "option": "--vegetationPointDensity",
      "utilityId": "window-fusion",
      "value": "-1"
    },
    {
      "option": "--boundaryType",
      "utilityId": "window-fusion",
      "value": "none"
    },
    {
      "option": "--tweak.lidarLoadMargin",
      "utilityId": "window-fusion",
      "value": "7"
    },
    {
      "option": "--errorStep",
      "utilityId": "window-mesh",
      "value": "0.05"
    },
    {
      "option": "--maxIters",
      "utilityId": "window-mesh",
      "value": "12"
    },
    {
      "option": "--lindstromTurk.numLodsToSkip",
      "utilityId": "window-mesh",
      "value": "10"
    },
    {
      "option": "--trim.enable",
      "utilityId": "window-mesh",
      "value": "1"
    },
    {
      "option": "--enableMipmapFiltering",
      "utilityId": "window-texture",
      "value": "1"
    }
  ],
  [VadstenaPipelineType.Supermesh]: []
}

export const defaultSceneAttributes: Record<VadstenaPipelineType, Array<{
  name: SceneAttributes,
  value: number
}>> = {
  [VadstenaPipelineType.Uav]: [
    {
      name: SceneAttributes.gsd,
      value: 0.05
    },
    {
      name: SceneAttributes.outputPointDensity,
      value: 1
    },
    {
      name: SceneAttributes.outputResolution,
      value: 0
    },
  ],
  [VadstenaPipelineType.Blk]: [
    {
      name: SceneAttributes.gsd,
      value: 0.005
    },
    {
      name: SceneAttributes.texelSize,
      value: 0.002
    }
  ],
  [VadstenaPipelineType.Supermesh]: [
    {
      name: SceneAttributes.gsd,
      value: 0.05
    },
    {
      name: SceneAttributes.outputPointDensity,
      value: 1
    },
    {
      name: SceneAttributes.outputResolution,
      value: 0
    },
    {
      name: SceneAttributes.meshEdgeLength,
      value: 0.024
    },
    {
      name: SceneAttributes.texelSize,
      value: 0.008
    }
  ]
}