import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex align-items-center justify-content-end" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex justify-content-center align-items-center" }
const _hoisted_10 = { class: "text-center" }

import SceneCreated from './SceneStatusViews/SceneCreated.vue'
import ScenePopulating from './SceneStatusViews/ScenePopulating.vue'
import SceneProcessing from './SceneStatusViews/SceneProcessing.vue'
import SceneInTechnicalQcReview from './SceneStatusViews/SceneInTechnicalQcReview.vue'
import SceneTechnicalQcFailed from './SceneStatusViews/SceneTechnicalQcFailed.vue'
import { useScenes } from '@/composables/useScenes';
import SceneActionPanel from './SceneActions/SceneActionPanel.vue';
import { SceneStatusAlias } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';
import SceneProcessingConfiguration from './SceneStatusViews/SceneProcessingConfiguration.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectSceneDetail',
  props: {
  projectId: {
    required: true,
    type: String
  },
  sceneId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const { getConfig } = useConfig()
const config = getConfig()

const props = __props

const { getScene } = useScenes()
const { scene, refetch } = getScene(props.sceneId, config?.POLLINTERVAL) 

async function onSceneUpdated() {
  await refetch()
}


return (_ctx: any,_cache: any) => {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, _createSlots({
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_unref(scene))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Scene: " + _toDisplayString(_unref(scene).name), 1))
                : (_openBlock(), _createBlock(_component_Skeleton, {
                    key: 1,
                    height: "2rem",
                    class: "mb-2"
                  }))
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_unref(scene))
                ? (_openBlock(), _createBlock(SceneActionPanel, {
                    key: 0,
                    scene: _unref(scene),
                    onSceneUpdated: onSceneUpdated,
                    "show-open-button": false
                  }, null, 8, ["scene"]))
                : (_openBlock(), _createBlock(_component_Skeleton, {
                    key: 1,
                    height: "2rem",
                    class: "mb-2"
                  }))
            ])
          ])
        ]),
        _: 2
      }, [
        (_unref(scene))
          ? {
              name: "content",
              fn: _withCtx(() => [
                (_unref(scene).automationIdle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_unref(scene).status?.alias === _unref(SceneStatusAlias).New)
                        ? (_openBlock(), _createBlock(SceneCreated, {
                            key: 0,
                            sceneId: __props.sceneId
                          }, null, 8, ["sceneId"]))
                        : (_unref(scene).status?.alias === _unref(SceneStatusAlias).Populating || _unref(scene).status?.alias === _unref(SceneStatusAlias).InputQcCdnDatasetPublishing)
                          ? (_openBlock(), _createBlock(ScenePopulating, {
                              key: 1,
                              sceneId: __props.sceneId
                            }, null, 8, ["sceneId"]))
                          : (_unref(scene).status?.alias === _unref(SceneStatusAlias).InputQcReview)
                            ? (_openBlock(), _createBlock(SceneInTechnicalQcReview, {
                                key: 2,
                                sceneId: __props.sceneId
                              }, null, 8, ["sceneId"]))
                            : (_unref(scene).status?.alias === _unref(SceneStatusAlias).InputQcFailed)
                              ? (_openBlock(), _createBlock(SceneTechnicalQcFailed, {
                                  key: 3,
                                  sceneId: __props.sceneId
                                }, null, 8, ["sceneId"]))
                              : (_unref(scene).status?.alias === _unref(SceneStatusAlias).InputQcPassed)
                                ? (_openBlock(), _createBlock(SceneProcessingConfiguration, {
                                    key: 4,
                                    sceneId: __props.sceneId
                                  }, null, 8, ["sceneId"]))
                                : (_unref(scene).status?.alias === _unref(SceneStatusAlias).Processing)
                                  ? (_openBlock(), _createBlock(SceneProcessing, {
                                      key: 5,
                                      sceneId: __props.sceneId
                                    }, null, 8, ["sceneId"]))
                                  : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_Skeleton, {
                            height: "2rem",
                            class: "mb-2"
                          }),
                          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Scene is waiting for automation", -1))
                        ])
                      ])
                    ]))
              ]),
              key: "0"
            }
          : undefined
      ]), 1024)
    ])
  ]))
}
}

})