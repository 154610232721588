import { gql } from "@/gql";

export const CREATE_SCENE = gql(`
mutation CREATE_SCENE(
  $id: ID!
  $projectId: Int!
  $name: String
  $notes: String
  $populationConfig: JSONObject
  $parentScenesId: [String!]
  $populationTypeAlias: PopulationTypeAlias!
  $vadstenaPipeline: String
) {
  createScene(
    id: $id
    projectId: $projectId
    name: $name
    notes: $notes
    populationConfig: $populationConfig
    parentScenesId: $parentScenesId
    populationTypeAlias: $populationTypeAlias
    vadstenaPipeline: $vadstenaPipeline
  ) {
    id
    notes
    attributes
    name
    inputQcCdnDataset {
      id
    }
    status {
      alias
    }
    vadstenaInputDmVolumeMountAlias
    vadstenaPipeline
  }
}
`);

export const START_SCENE_POPULATION = gql(`
mutation StartScenePopulation($dmCollocationId: String!, $outputDmVolumeMountAlias: String!, $sceneId: String!, $logDmVolumeMountAlias: String!) {
  startScenePopulation(dmCollocationId: $dmCollocationId, outputDmVolumeMountAlias: $outputDmVolumeMountAlias, sceneId: $sceneId, logDmVolumeMountAlias: $logDmVolumeMountAlias) {
    id
  }
}
`);

export const UPDATE_SCENE = gql(`
mutation UPDATE_SCENE($updateSceneId: ID!, $requestToVolumeMounting: Boolean, $options: [SceneOptionInput!], $gsd: Float, $texelSize: Float, $meshEdgeLength: Float, $notes: String, $name: String, $attributes: JSONObject, $populationConfig: JSONObject, $aoiInputFiles: AoiInputFiles, $requestToPopulating: Boolean, $requestToInputQcReview: Boolean, $requestToInputQcFailed: Boolean, $requestToCancelPopulation: Boolean, $requestToVolumeUnmounting: Boolean, $requestToInputQcPassed: Boolean, $requestToArchive: Boolean, $requestToPurge: Boolean, $requestToCancelScene: Boolean, $clusterId: String) {
  updateScene(id: $updateSceneId, requestToVolumeMounting: $requestToVolumeMounting, options: $options, gsd: $gsd, texelSize: $texelSize, meshEdgeLength: $meshEdgeLength, notes: $notes, name: $name, attributes: $attributes, populationConfig: $populationConfig, aoiInputFiles: $aoiInputFiles, requestToPopulating: $requestToPopulating, requestToInputQcReview: $requestToInputQcReview, requestToInputQcFailed: $requestToInputQcFailed, requestToCancelPopulation: $requestToCancelPopulation, requestToVolumeUnmounting: $requestToVolumeUnmounting, requestToInputQcPassed: $requestToInputQcPassed, requestToArchive: $requestToArchive, requestToPurge: $requestToPurge, requestToCancelScene: $requestToCancelScene, clusterId: $clusterId) {
    options {
      option
      utilityId
      value
    }
    id
    attributes
    gsd
    name
    notes
    populationConfig
    texelSize
    requestToArchive
    requestToCancelPopulation
    requestToCancelScene
    requestToInputQcFailed
    requestToInputQcPassed
    requestToInputQcReview
    requestToPopulating
    requestToPurge
    requestToVolumeMounting
    requestToVolumeUnmounting
    inputQcCdnDataset {
      id
    }
    status {
      alias
      name
    }
    project {
      id
      name
    }
    meshEdgeLength
  }
}
`);
