<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          Create new scene
          <br />
          <Badge value="Expert mode" severity="danger"></Badge>
        </template>
        <template #content>
          <Form
            :initial-values="initialValues"
            :validation-schema="schema"
            class="p-fluid"
          >
            <div class="grid">
              <div class="col-6">
                <div>
                  <label for="id">Scene Id</label>
                  <InputText
                    v-model="formData.id"
                    name="id"
                    label="Scene id"
                    @change="onSceneIdChange"
                  ></InputText>
                  <label for="id" v-if="formData.idExist" class="p-error"
                    >Scene Id already exist</label
                  >
                </div>

                <div>
                  <label for="name">Scene Name</label>
                  <InputText
                    v-model="formData.name"
                    name="name"
                    label="Scene name"
                  ></InputText>
                </div>

                <div>
                  <label for="notes">Notes</label>
                  <InputText
                    v-model="formData.notes"
                    name="notes"
                    label="Notes"
                  ></InputText>
                </div>

                <div>
                  <label for="vadstenaPipeline">Pipeline Type</label>
                  <Dropdown
                    v-model="formData.vadstenaPipeline"
                    :options="pipelineTypes"
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionValue="value"
                    placeholder="Select a Pipeline"
                    class="w-full"
                  >
                    <template #optiongroup="slotProps">
                      <div class="flex align-items-center">
                        <i class="pi pi-folder"></i> &nbsp;
                        <div>{{ slotProps.option.label }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </div>
              </div>
              <div class="col-6">
                <Panel header="Locations settings">
                  <p
                    class="text-center font-italic"
                    v-if="!anyShowDataBrowseButton"
                  >
                    No actions available.
                  </p>
                  <div class="grid">
                    <div class="col-6 col-md-4" v-if="showDataBrowseButton.srs">
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'srs')"
                        title="label"
                        button-text="Locate SRS file"
                        leaf="file"
                      >
                      </DataBrowserSelect>
                    </div>
                    <div class="col-6 col-md-4" v-if="showDataBrowseButton.dem">
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'dem')"
                        title="label"
                        button-text="Locate DEM dir"
                        leaf="dir"
                      >
                      </DataBrowserSelect>
                    </div>
                    <div
                      class="col-6 col-md-4"
                      v-if="showDataBrowseButton.imageDir"
                    >
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'imageDir')"
                        title="Add Image dir"
                        button-text="Add Image dir"
                        leaf="dir"
                      >
                      </DataBrowserSelect>
                    </div>
                    <div
                      class="col-6 col-md-4"
                      v-if="showDataBrowseButton.eopsDir"
                    >
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'eopsDir')"
                        title="Add Eops dir"
                        button-text="Add Eops dir"
                        leaf="dir"
                      >
                      </DataBrowserSelect>
                    </div>
                    <div
                      class="col-6 col-md-4"
                      v-if="showDataBrowseButton.trajectoryDir"
                    >
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'trajectoryDir')"
                        title="Add Trajectory dir"
                        button-text="Add Trajectory dir"
                        leaf="dir"
                      >
                      </DataBrowserSelect>
                    </div>
                    <div
                      class="col-6 col-md-4"
                      v-if="showDataBrowseButton.lidarDir"
                    >
                      <DataBrowserSelect
                        @select-file="onSelectLocation($event, 'lidarDir')"
                        title="Add LIDAR dir"
                        button-text="Add LIDAR dir"
                        leaf="dir"
                      >
                      </DataBrowserSelect>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>

            <h4>Ingest Configuration</h4>

            <div class="grid">
              <div class="col-6">
                <JsonEditor
                  label="populationConfig"
                  v-model="formData.populationConfig"
                />
              </div>
              <div class="col-6">
                <Panel>
                  <template #header>
                    <div class="flex w-full">
                      <div class="flex-grow-1 font-bold">Config preview</div>
                      <div class="flex-none">
                        <Badge
                          v-if="!populationConfigSerializedValid"
                          value="Fill placeholders!"
                          severity="danger"
                        ></Badge>
                        <Badge
                          v-else
                          value="Looks ok"
                          severity="success"
                        ></Badge>
                      </div>
                    </div>
                  </template>
                  <div class="w-full overflow-y-auto">
                    <p v-if="loadingSerializedPopulationConfig">Loading...</p>
                    <pre v-else v-html="populationConfigSerializedValidated" />
                  </div>
                </Panel>
              </div>
            </div>

            <div class="flex justify-content-end">
              <Button
                :disabled="!populationConfigSerializedValid"
                :label="'Create scene'"
                @click="onComplete"
                icon="fa-solid fa-check"
                class="p-button-success mr-2 mb-2 mlwn-button-submit float-right"
              ></Button>
            </div>
          </Form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRaw, watch } from "vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import {
  Create_SceneMutationVariables,
  PopulationTypeAlias,
} from "@/gql/graphql";
import { useRouter } from "vue-router";
import { useScenes } from "@/composables/useScenes";
import { useToast } from "primevue/usetoast";
import {
  VadstenaPipelineType,
  groupedPipelinePresets,
  defaultPopulationConfig,
} from "@/components/Helpers/PipelineConfigTemplates";

import DataBrowserSelect from "@/components/DataBrowser/DataBrowseSelect.vue";

const { createScene, sceneExist, serializePopulationConfig } = useScenes();
const router = useRouter();
const toast = useToast();

const props = defineProps({
  projectId: {
    required: true,
    type: String,
  },
});

const pipelineTypes = ref(groupedPipelinePresets);

const initialValues = ref({
  id: "",
  name: "",
  notes: "",
  idExist: false,
  vadstenaPipeline: pipelineTypes.value[0].items[0].value,
  populationConfig:
    defaultPopulationConfig[pipelineTypes.value[0].items[0].value],
});

const formData = ref(toRaw(initialValues.value));

const {
  populationConfigSerialized,
  loading: loadingSerializedPopulationConfig,
  refetch: refetchSerializedPopulationConfig,
} = serializePopulationConfig(formData.value.populationConfig);

/**
 * Validation setup
 */
const schema = yup.object({
  id: yup.string().min(3).required().label("id"),
  name: yup.string().min(3).required().label("name"),
  label: yup.string().required().label("label"),
  pipelineType: yup.string().required().label("pipelineType"),
  populationConfig: yup.object().required(),
  vadstenaPipeline: yup.string().required(),
});

function onComplete() {
  onCreateScene({
    projectId: parseInt(props.projectId),
    populationTypeAlias: PopulationTypeAlias.Ingest,
    ...toRaw(formData.value),
  });
}

// additional UI components

const showDataBrowseButton = computed(() => ({
  srs: [VadstenaPipelineType.Blk, VadstenaPipelineType.Uav].includes(
    formData.value.vadstenaPipeline
  ),
  dem: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Supermesh,
    VadstenaPipelineType.Uav,
  ].includes(formData.value.vadstenaPipeline),
  imageDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  trajectoryDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  lidarDir: [
    VadstenaPipelineType.Blk,
    VadstenaPipelineType.Uav,
    VadstenaPipelineType.Supermesh,
  ].includes(formData.value.vadstenaPipeline),
  eopsDir: [VadstenaPipelineType.Supermesh].includes(
    formData.value.vadstenaPipeline
  ),
}));
const anyShowDataBrowseButton = computed(() =>
  Object.values(showDataBrowseButton.value).some((v) => v === true)
);

const onSelectLocation = async (data: any, locationType: string) => {
  switch (locationType) {
    case "srs":
      formData.value.populationConfig.srs =
        formData.value.populationConfig.srs || {};
      formData.value.populationConfig.srs = {
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      };
      break;
    case "dem":
      formData.value.populationConfig.dem =
        formData.value.populationConfig.dem || {};
      formData.value.populationConfig.dem = {
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      };
      break;
    case "imageDir":
      formData.value.populationConfig.imageDir =
        formData.value.populationConfig.imageDir || [];
      formData.value.populationConfig.imageDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "eopsDir":
      formData.value.populationConfig.eopsDir =
        formData.value.populationConfig.eopsDir || [];
      formData.value.populationConfig.eopsDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "trajectoryDir":
      formData.value.populationConfig.trajectoryDir =
        formData.value.populationConfig.trajectoryDir || [];
      formData.value.populationConfig.trajectoryDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
    case "lidarDir":
      formData.value.populationConfig.lidarDir =
        formData.value.populationConfig.lidarDir || [];
      formData.value.populationConfig.lidarDir.push({
        path: data.path,
        volumeMountAlias: data.volumeMountAlias,
      });
      break;
  }
  refetchSerializedPopulationConfig(formData.value.populationConfig);
};

const onCreateScene = async (scene: Create_SceneMutationVariables) => {
  try {
    const res = await createScene(scene);

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully created",
        life: 3000,
      });
      window.localStorage.removeItem("mlwn-scene-form");
      router.push({
        name: "project-scene-detail",
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while creating",
        life: 3000,
      });
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while creating. " + err?.message,
      life: 3000,
    });
    console.error(err);
  }
};

const onSceneIdChange = () => {
  checkSceneIdExist(formData.value.id);
};

const checkSceneIdExist = (id: string) => {
  formData.value.idExist = false;

  const { exist } = sceneExist(id);
  watch(exist, () => {
    if (exist.value) {
      formData.value.idExist = exist.value === true;
    }
  });
};

watch(
  () => formData.value.populationConfig,
  (value) => {
    refetchSerializedPopulationConfig(value);
  }
);

watch(
  () => formData.value.vadstenaPipeline,
  (value) => {
    formData.value.populationConfig = defaultPopulationConfig[value];
  }
);

const populationConfigSerializedValidated = computed(() => {
  return populationConfigSerialized.value?.replace(
    /(__FILL__)/g,
    '<span class="p-error font-bold">__FILL__</span>'
  );
});
const populationConfigSerializedValid = computed(() => {
  return populationConfigSerialized.value?.match(/(__FILL__)/g) === null;
});
</script>
